<template>
    <div>
      <DashboardHeader />
      <GeneratedThirteenthMonthForm />
      <DashboardFooter />
    </div>
</template>

<script>
  import DashboardHeader from '../components/DashboardHeader'
  import GeneratedThirteenthMonthForm from '../components/GeneratedThirteenthMonthForm'
  import DashboardFooter from '../components/DashboardFooter'

  export default {
    name: 'PayrollGeneratedView',

    components: {
      DashboardHeader,
      GeneratedThirteenthMonthForm,
      DashboardFooter,
    },
  }
</script>