<template>
  <div>
    <DashboardHeader />
    <UserPayslipForm />
    <DashboardFooter />
  </div>
</template>

<script>
  import DashboardHeader from '../components/DashboardHeader'
  import UserPayslipForm from '../components/UserPayslipForm'
  import DashboardFooter from '../components/DashboardFooter'

  export default {
    name: 'UserPayslipView',

    components: {
      DashboardHeader,
      UserPayslipForm,
      DashboardFooter
    },
  }
</script>