<template>
    <div class="back-btn">
        <v-btn icon
        @click="backButton"
    >
        <v-icon>mdi-arrow-left</v-icon>
    </v-btn>
    </div>
</template>

<script>
  export default {
    name: 'BackBtn',

    methods: {
      backButton() {
        this.$router.go(-1);
      },
    }
  }
</script>
