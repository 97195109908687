<template>
    <v-container class="mt-16">
        <back-btn></back-btn>
        <h2 class="text-center">Update Employee Payroll</h2>
        <v-form class="mt-5">
            <v-row>
                <v-col
                    cols="6"
                    sm="6"
                    md="4"
                >   
                    <input type="hidden" :value="employee_id">
                    <v-text-field
                        v-model="id_number"
                        label="ID NUMBER:"
                        readonly
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="fullname"
                        label="NAME:"
                        readonly
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="department"
                        label="DEPARTMENT:"
                        readonly
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="position"
                        label="POSITION:"
                        readonly
                    >
                    </v-text-field>
                </v-col>
                <v-col
                    cols="6"
                    sm="6"
                    md="4"
                >
                    <v-text-field
                        v-model="tin_no"
                        label="TIN:"
                        readonly
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="sss_no"
                        label="SSS NUMBER:"
                        readonly
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="phic_no"
                        label="PHIC NUMBER:"
                        readonly
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="hdmf_no"
                        label="HDMF NUMBER:"
                        readonly
                    >
                    </v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    sm="12"
                    md="4"
                >
                    <v-menu
                        ref="menu1"
                        v-model="menu1"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="dateFormatted"
                            label="PAY DATE"
                            hint="MM/DD/YYYY format"
                            persistent-hint
                            prepend-icon="mdi-calendar"
                            v-bind="attrs"
                            @blur="date = parseDate(dateFormatted)"
                            v-on="on"
                        ></v-text-field>
                        </template>
                        <v-date-picker
                        v-model="date"
                        no-title
                        @input="menu1 = false"
                        ></v-date-picker>
                    </v-menu>
                    
                    <v-menu
                        ref="menu2"
                        v-model="menu2"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="dateRangeText"
                            label="DATE COVERED"
                            hint="MM/DD/YYYY format"
                            persistent-hint
                            prepend-icon="mdi-calendar"
                            v-bind="attrs"
                            @blur="daterange = parseDateRange(dateRangeFormatted)"
                            v-on="on"
                        ></v-text-field>
                        </template>
                        <v-date-picker
                        v-model="dates"
                        range
                        no-title
                        @input="menu2 = false"
                        ></v-date-picker>
                    </v-menu>

                    <v-text-field
                        v-model="tax_status"
                        label="TAX STATUS:"
                        readonly
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="daily_rate"
                        label="DAILY RATE:"
                        readonly
                    >
                    </v-text-field>
                </v-col>
            </v-row>
            
            <v-row>
                <v-col
                    cols="6"
                    sm="6"
                    md="3"
                >
                    <v-text-field
                        v-model="basic_pay"
                        label="BASIC PAY:"
                        outlined
                        dense
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="holiday_pay"
                        label="REGULAR HOLIDAY:"
                        outlined
                        dense
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="special_holiday_pay"
                        label="SPECIAL HOLIDAY:"
                        outlined
                        dense
                    >
                    </v-text-field>
                </v-col>
                <v-col
                    cols="6"
                    sm="6"
                    md="3"
                >
                    <v-text-field
                        v-model="ord_nd"
                        label="NIGHT DIFF:"
                        outlined
                        dense
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="ot"
                        label="OVERTIME PAY:"
                        outlined
                        dense
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="ord_ot"
                        label="OVERTIME RD/OT:"
                        outlined
                        dense
                    >
                    </v-text-field>
                </v-col>
                <v-col
                    cols="6"
                    sm="6"
                    md="3"
                >
                    <v-text-field
                        v-model="undertime"
                        label="UNDERTIME:"
                        outlined
                        dense
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="tardiness"
                        label="TARDINESS:"
                        outlined
                        dense
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="absences"
                        label="ABSENCES:"
                        outlined
                        dense
                    >
                    </v-text-field>
                </v-col>
                <v-col
                    cols="6"
                    sm="6"
                    md="3"
                >
                    <v-text-field
                        v-model="sss"
                        label="SSS CONTRIBUTION:"
                        outlined
                        dense
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="phic"
                        label="PHIC CONTRIBUTION:"
                        outlined
                        dense
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="hdmf_pagibig"
                        label="HDMF/PAG-IBIG CONTRIBUTION:"
                        outlined
                        dense
                    >
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col
                    cols="6"
                    sm="6"
                    md="3"
                >
                    <v-text-field
                        v-model="rice_allowance"
                        label="RICE SUBSIDY:"
                        outlined
                        dense
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="laundry_allowance"
                        label="LAUNDRY:"
                        outlined
                        dense
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="uniform_allowance"
                        label="UNIFORM:"
                        outlined
                        dense
                    >
                    </v-text-field>
                </v-col>
                <v-col
                    cols="6"
                    sm="6"
                    md="3"
                >
                    
                    <v-text-field
                        v-model="transportation_allowance"
                        label="TRANSPORTATION:"
                        outlined
                        dense
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="paid_leaves"
                        label="PAID LEAVES:"
                        outlined
                        dense
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="other_earnings"
                        label="OTHER INCOME:"
                        outlined
                        dense
                    >
                    </v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    sm="12"
                    md="6"
                >
                    <v-row>
                        <v-col
                            cols="6"
                            sm="6"
                            md="6"
                        >
                            <v-text-field
                                v-model="salary_dispute"
                                label="SALARY DISPUTE:"
                                outlined
                                dense
                            >
                            </v-text-field>
                        </v-col>
                        <v-col
                            cols="6"
                            sm="6"
                            md="6"
                        >
                            <v-text-field
                                v-model="sales_commission"
                                label="SALES COMMISSION:"
                                outlined
                                dense
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            cols="6"
                            sm="6"
                            md="6"
                        >
                            <v-text-field
                                v-model="thirteenth_month_pay"
                                label="13th MONTH PAY"
                                outlined
                                dense
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-col>    
            </v-row>
            <v-row>
                <v-col
                    cols="6"
                    sm="6"
                    md="3"
                >
                    <v-text-field
                        v-model="hmo"
                        label="HMO:"
                        outlined
                        dense
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="sss_loan"
                        label="SSS LOAN:"
                        outlined
                        dense
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="hdmf_loan"
                        label="HDMF LOAN:"
                        outlined
                        dense
                    >
                    </v-text-field>
                </v-col>
                <v-col
                    cols="6"
                    sm="6"
                    md="3"
                >
                    
                    <v-text-field
                        v-model="phic_retroactive"
                        label="PHIC RETROACTIVE:"
                        outlined
                        dense
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="withholding_tax"
                        label="WITHHOLDING TAX:"
                        outlined
                        dense
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="others"
                        label="OTHERS:"
                        outlined
                        dense
                    >
                    </v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    sm="12"
                    md="6"
                >
                    <v-row>
                        <v-col
                            cols="6"
                        >
                            <v-text-field
                                v-model="total_compensation_deduction"
                                label="TOTAL COMPENSATION DEDUCTIONS:"
                                class="font-weight-black"
                                color="#000000"
                                disabled
                            >
                            </v-text-field>
                        </v-col>
                        <v-col
                            cols="6"
                        >
                            <v-text-field
                                v-model="total_compensation"
                                label="TOTAL TAXABLE COMPENSATION"
                                class="font-weight-black"
                                color="#000000"
                                disabled
                            >
                            </v-text-field>
                        </v-col>
                        <v-col
                            cols="6"
                        >
                            <v-text-field
                                v-model="gross_salary"
                                label="GROSS SALARY:"
                                class="font-weight-black"
                                color="#000000"
                                disabled
                            >
                            </v-text-field>
                        </v-col>
                        <v-col
                            cols="6"
                        >
                            <v-text-field
                                v-model="total_deduction"
                                label="TOTAL DEDUCTIONS:"
                                class="font-weight-black"
                                color="#000000"
                                disabled
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-text-field
                        v-model="take_home_pay"
                        label="TOTAL NET SALARY:"
                        class="total-salary-label-color font-weight-black"
                        color="#175EAC"
                        disabled
                    >
                    </v-text-field>
                </v-col>
            </v-row>

            <v-row>
                <v-col
                    cols="6"
                >
                    <v-text-field
                        v-model="prepared_by"
                        label="Prepared by:"
                        readonly
                    >
                    </v-text-field>
                </v-col>
                <v-col
                    cols="6"
                >
                    <v-text-field
                        v-model="received_by"
                        label="Received by:"
                        readonly
                    >
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-alert
                        type="success"
                        :value = "alert"
                        class="text-center"
                    >
                        {{success}}
                    </v-alert>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-btn 
                        block 
                        x-large
                        color="primary"
                        @click="updatePayroll"
                    >
                        Update Payroll
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>
    </v-container>
</template>

<style>
    .total-salary-label-color .v-label{
        color: #175EAC !important;
    }
</style>

<script>
  import BackBtn from '../components/BackBtn'
  import axios from 'axios';

  export default {
    name: 'PayrollForm',

    components: {
        BackBtn
    },

    data: vm => ({
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        dateFormatted: vm.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),
        menu1: false,
        dates: [(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10), (new Date(Date.now() - (new Date()).getTimezoneOffset() * 300000)).toISOString().substr(0, 10)],
        dateRangeFormatted: [vm.formatDateRange((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)), vm.formatDateRange((new Date(Date.now() - (new Date()).getTimezoneOffset() * 300000)).toISOString().substr(0, 10)) ],
        menu2: false,

        employee_id: '',   
        fullname: '',
        id_number: '',
        tin_no: '0',
        sss_no: '0',
        phic_no: '0',
        hdmf_no: '0',
        pay_date: '0',
        tax_status: '',
        department: '',
        position: '',
        basic_pay: 0,
        rice_allowance: 0,
        laundry_allowance: 0,
        uniform_allowance: 0,
        transportation_allowance: 0,
        ot: 0,
        ord_ot: 0,
        ord_nd: 0,
        paid_leaves: 0,
        holiday_pay: 0,
        special_holiday_pay: 0,
        undertime: 0,
        tardiness: 0,
        absences: 0,
        other_earnings: 0,
        sss_loan: 0,
        hdmf_loan: 0,
        others: 0,
        sss: 0,
        phic: 0,
        hdmf_pagibig: 0,
        phic_retroactive: 0,
        withholding_tax: 0,
        hmo: 0,
        salary_dispute: 0,
        sales_commission: 0,
        thirteenth_month_pay: 0,
        prepared_by: '',
        received_by: '',
        attendance: '',
        attLogs: [],
        date_covered: '',
        alert: false,
        success: '',
        dateRangeText: '',
    }),

    computed: {
        computedDateFormatted () {
            return this.formatDate(this.date)
        },

        gross_salary: {
            get: function () {
                if(this.basic_pay == '0') {
                    return this.thirteenth_month_pay;
                }else{
                    return this.getGrossSalary();
                }
                //return this.getGrossSalary();
            },
            set: function () {}
        },

        total_compensation_deduction: {
            get: function () {
                return this.getTotalCompensationDeduction();
            },
            set: function () {}
         },

        total_compensation: {
            get: function () {
               return this.getTotalCompensation();
            },
            set: function () {
            }
            
        },

        total_deduction: {
            get: function() {
                return this.getTotalDeduction();
            },
            set: function(newVal) {
                return  parseFloat(newVal);
            }
        },

        daily_rate: {
            get: function() {
                return this.getDailyRate();
            },
            set: function() {
                let dailyRate = 0;
                return dailyRate;
            }
        },

        totalAllowance: {
            get: function () {
                return this.getTotalAllowance();
            },
        },

        loan_tax_other_deduction () {
            let loan_tax_other_deduction = parseFloat(this.hmo) + parseFloat(this.sss_loan) + parseFloat(this.hdmf_loan) 
                    + parseFloat(this.phic_retroactive) + parseFloat(this.withholding_tax) + parseFloat(this.others);

            return loan_tax_other_deduction;
        },

        take_home_pay: {
            get: function () {
                return this.getTakeHomePay();
            },
            set: function () {}
        },
    },

    watch: {
        // eslint-disable-next-line no-unused-vars
      date (val) {
        this.dateFormatted = this.formatDate(this.date)
      },

    // eslint-disable-next-line no-unused-vars
      dates (val) {
        this.dateRangeFormatted = this.formatDateRange(this.dates)
      },

      alert (val) {
        if (!val) return

        setTimeout(() => (this.alert = false), 3000)
      },

      totalAllowance() {
        this.allowance = this.getTotalAllowance()
      }
    },


    methods: {
        formatDate (date) {
            if (!date) return null

            const [year, month, day] = date.split('-')
            return `${month}/${day}/${year}`
        },
        parseDate (date) {
            if (!date) return null

            const [month, day, year] = date.split('/')
            return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
        },

        formatDateRange (date) {
            if (!date) return null

            const [year, month, day] = date.toString().split('-')
            return `${month}/${day}/${year}`
        },
        parseDateRange (date) {
            if (!date) return null
            
            const [month, day, year] = date.toString().split('/')
            return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
        },

        getDailyRate () {
            // Basic Pay x 2 x 12(months) / 261 days
            let dailyRate = parseFloat(this.basic_pay) * 2 * 12 / 261;
            return dailyRate.toFixed(3);
        },

        getGrossSalary () {
            let grossSalary = parseFloat(this.total_compensation) + parseFloat(this.totalAllowance) + parseFloat(this.other_earnings);
            return grossSalary.toFixed(2);
        },

        getTotalDeduction () {
            let totalDeduction = parseFloat(this.total_compensation_deduction) + parseFloat(this.loan_tax_other_deduction);

            return totalDeduction.toFixed(2);
        },

        getTotalCompensationDeduction () {
            let totalCompensationDeduction = parseFloat(this.absences) + parseFloat(this.tardiness) + parseFloat(this.undertime)
                + parseFloat(this.hdmf_pagibig) + parseFloat(this.sss) + parseFloat(this.phic);

            return totalCompensationDeduction.toFixed(2);
        },

        getTotalCompensation () {
            let totalCompensation = parseFloat(this.basic_pay) + parseFloat(this.ot) + parseFloat(this.ord_ot) 
            + parseFloat(this.ord_nd) + parseFloat(this.paid_leaves) + parseFloat(this.holiday_pay) + parseFloat(this.special_holiday_pay)
            + parseFloat(this.salary_dispute) + parseFloat(this.sales_commission) - this.total_compensation_deduction;
            
            return totalCompensation.toFixed(2);
        },

        getTotalAllowance () {
            let totalAllowance = parseFloat(this.rice_allowance) + parseFloat(this.laundry_allowance) + parseFloat(this.uniform_allowance)
                                 + parseFloat(this.transportation_allowance);
            return totalAllowance;
        },

        getTakeHomePay () {
            let takeHomePay = parseFloat(this.gross_salary) - parseFloat(this.loan_tax_other_deduction);
            return takeHomePay.toFixed(2);
        },


        updatePayroll() {
            const data = JSON.parse(localStorage.getItem("tokenData"));
            const payrollID = JSON.parse(localStorage.getItem("payrollID"));
            
            let payrollData = {
                pay_date: this.dateFormatted,
                date_covered: this.dateRangeText,
                daily_rate: this.daily_rate,
                basic_pay: this.basic_pay,
                rice_allowance: this.rice_allowance,
                laundry_allowance: this.laundry_allowance,
                uniform_allowance: this.uniform_allowance,
                transportation_allowance: this.transportation_allowance,
                allowance: this.allowance,
                ot: this.ot,
                ord_ot: this.ord_ot,
                ord_nd: this.ord_nd,
                paid_leaves: this.paid_leaves,
                holiday_pay: this.holiday_pay,
                special_holiday_pay: this.special_holiday_pay,
                other_earnings: this.other_earnings,
                salary_dispute: this.salary_dispute,
                sales_commission: this.sales_commission,
                total_compensation: this.total_compensation, 
                total_compensation_deduction: this.total_compensation_deduction,
                absences: this.absences,
                tardiness: this.tardiness,
                undertime: this.undertime,
                sss_loan: this.sss_loan,
                hdmf_loan: this.hdmf_loan,
                others: this.others,
                sss: this.sss,
                phic: this.phic,
                hdmf_pagibig: this.hdmf_pagibig,
                phic_retroactive: this.phic_retroactive,
                withholding_tax: this.withholding_tax,
                hmo: this.hmo,
                gross_salary: this.gross_salary,
                total_deductions: this.total_deduction,
                take_home_pay: this.take_home_pay,
                prepared_by: this.prepared_by,
            }
            axios
            .post(process.env.VUE_APP_ROOT_API + '/api/payroll/update/' + payrollID, payrollData, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${data.access_token}`
                }
            })
            .then(response => {
                this.alert = true;
                this.success = response.data.success;
            })
            .catch(error => {
                console.log(error)
            })
        }
    },

    mounted() {
      const data = JSON.parse(localStorage.getItem("tokenData"));
      const userID = JSON.parse(localStorage.getItem("userID"));
      const payrollID = JSON.parse(localStorage.getItem("payrollID"));

      axios
      .get(process.env.VUE_APP_ROOT_API + '/api/getfullname', {
        headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${data.access_token}`
                }
      })
      .then(response => {
          this.prepared_by = response.data.first_name + " " + response.data.last_name;
      })
      .catch(error => {
          console.log(error)
      })

      axios
      .get(process.env.VUE_APP_ROOT_API + '/api/payroll/getuser/'+payrollID+'/'+userID, {
        headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${data.access_token}`
                }
      })
      .then(response => {
            this.fullname = response.data.fullname
            this.id_number = response.data.id_number
            this.tin_no = response.data.tin_no
            this.sss_no = response.data.sss_no
            this.phic_no = response.data.phic_no
            this.hdmf_no = response.data.hdmf_no
            this.dateFormatted = response.data.pay_date
            this.dateRangeText = response.data.date_covered
            this.tax_status = response.data.tax_status
            this.department =  response.data.department
            this.position = response.data.position
            this.daily_rate = response.data.daily_rate
            this.basic_pay = response.data.basic_pay
            this.rice_allowance = response.data.rice_allowance
            this.laundry_allowance = response.data.laundry_allowance
            this.uniform_allowance = response.data.uniform_allowance
            this.transportation_allowance = response.data.transportation_allowance
            this.ot = response.data.ot
            this.ord_ot = response.data.ord_ot
            this.ord_nd = response.data.ord_nd
            this.paid_leaves = response.data.paid_leaves
            this.holiday_pay = response.data.holiday_pay
            this.special_holiday_pay = response.data.special_holiday_pay
            this.other_earnings = response.data.other_earnings
            this.salary_dispute = response.data.salary_dispute
            this.sales_commission = response.data.sales_commission
            this.total_compensation = response.data.total_compensation
            this.total_compensation_deduction = response.data.total_compensation_deduction
            this.absences = response.data.absences
            this.tardiness = response.data.tardiness
            this.undertime = response.data.undertime
            this.sss_loan = response.data.sss_loan
            this.hdmf_loan = response.data.hdmf_loan
            this.others = response.data.others
            this.sss = response.data.sss
            this.phic = response.data.phic
            this.hdmf_pagibig = response.data.hdmf_pagibig
            this.phic_retroactive = response.data.phic_retroactive
            this.withholding_tax = response.data.withholding_tax
            this.hmo = response.data.hmo
            this.thirteenth_month_pay = response.data.total_thirteenth_month_pay
            this.gross_salary = response.data.gross_salary
            this.total_deduction = response.data.total_deductions
            this.take_home_pay = response.data.take_home_pay
            this.prepared_by = response.data.prepared_by
            this.received_by = response.data.received_by

            console.log(response.data);
      })
      .catch(error => {
          console.log(error)
      })
    },
  }
</script>