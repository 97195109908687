<template>
    <v-container class="mt-16">
        <back-btn></back-btn>
        <h2 class="text-center">Create Employee Payroll</h2>
        <v-form class="mt-5">
            <v-row>
                <v-col
                    cols="12"
                    sm="12"
                    md="4"
                >   
                    <input type="hidden" :value="employee_id">
                    <v-text-field
                        v-model="id_number"
                        label="ID NUMBER:"
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="fullname"
                        label="NAME:"
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="department"
                        label="DEPARTMENT:"
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="position"
                        label="POSITION:"
                    >
                    </v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    sm="12"
                    md="4"
                >
                    <v-text-field
                        v-model="tin_no"
                        label="TIN:"
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="sss_no"
                        label="SSS NUMBER:"
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="phic_no"
                        label="PHIC NUMBER:"
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="hdmf_no"
                        label="HDMF NUMBER:"
                    >
                    </v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    sm="12"
                    md="4"
                >
                    <v-menu
                        ref="menu1"
                        v-model="menu1"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="dateFormatted"
                            label="PAY DATE"
                            hint="MM/DD/YYYY format"
                            persistent-hint
                            prepend-icon="mdi-calendar"
                            v-bind="attrs"
                            @blur="date = parseDate(dateFormatted)"
                            v-on="on"
                            @change="updateDateCovered(date)"
                        ></v-text-field>
                        </template>
                        <v-date-picker
                        v-model="date"
                        no-title
                        @input="menu1 = false"
                        ></v-date-picker>
                    </v-menu>
                    
                    <v-menu
                        ref="menu2"
                        v-model="menu2"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="dateRangeCovered"
                            label="DATE COVERED"
                            hint="MM/DD/YYYY format"
                            persistent-hint
                            prepend-icon="mdi-calendar"
                            v-bind="attrs"
                            @blur="daterange = parseDateRange(dateRangeFormatted)"
                            v-on="on"
                            disabled
                        ></v-text-field>
                        </template>
                        <v-date-picker
                        v-model="dates"
                        range
                        no-title
                        @input="menu2 = false"
                        ></v-date-picker>
                    </v-menu>

                    <v-text-field
                        v-model="civil_status"
                        label="TAX STATUS:"
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="daily_rate"
                        label="DAILY RATE:"
                        readonly
                    >
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col
                    cols="6"
                    sm="6"
                    md="3"
                >
                    <v-text-field
                        v-model="basic_pay"
                        label="BASIC PAY:"
                        outlined
                        dense
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="holiday_pay"
                        label="REGULAR HOLIDAY:"
                        outlined
                        dense
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="special_holiday_pay"
                        label="SPECIAL HOLIDAY:"
                        outlined
                        dense
                    >
                    </v-text-field>
                </v-col>
                <v-col
                    cols="6"
                    sm="6"
                    md="3"
                >
                    <v-text-field
                        v-model="ord_nd"
                        label="NIGHT DIFF:"
                        outlined
                        dense
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="ot"
                        label="OVERTIME PAY:"
                        outlined
                        dense
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="ord_ot"
                        label="OVERTIME RD/OT:"
                        outlined
                        dense
                    >
                    </v-text-field>
                </v-col>
                <v-col
                    cols="6"
                    sm="6"
                    md="3"
                >
                    <v-text-field
                        v-model="undertime"
                        label="UNDERTIME:"
                        outlined
                        dense
                        disabled
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="tardiness"
                        label="TARDINESS:"
                        outlined
                        dense
                        disabled
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="absences"
                        label="ABSENCES:"
                        outlined
                        dense
                        disabled
                    >
                    </v-text-field>
                </v-col>
                <v-col
                    cols="6"
                    sm="6"
                    md="3"
                >
                    <v-text-field
                        v-model="sss"
                        label="SSS CONTRIBUTION:"
                        outlined
                        dense
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="phic"
                        label="PHIC CONTRIBUTION:"
                        outlined
                        dense
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="hdmf_pagibig"
                        label="HDMF/PAG-IBIG CONTRIBUTION:"
                        outlined
                        dense
                    >
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col
                    cols="6"
                    sm="6"
                    md="3"
                >
                    <v-text-field
                        v-model="rice_allowance"
                        label="RICE SUBSIDY:"
                        outlined
                        dense
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="laundry_allowance"
                        label="LAUNDRY:"
                        outlined
                        dense
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="uniform_allowance"
                        label="UNIFORM:"
                        outlined
                        dense
                    >
                    </v-text-field>
                </v-col>
                <v-col
                    cols="6"
                    sm="6"
                    md="3"
                >
                    
                    <v-text-field
                        v-model="transportation_allowance"
                        label="TRANSPORTATION:"
                        outlined
                        dense
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="paid_leaves"
                        label="PAID LEAVES:"
                        outlined
                        dense
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="other_earnings"
                        label="OTHER INCOME:"
                        outlined
                        dense
                    >
                    </v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    sm="12"
                    md="6"
                >
                    <v-row>
                        <v-col
                            cols="6"
                            sm="6"
                            md="6"
                        >
                            <v-text-field
                                v-model="salary_dispute"
                                label="SALARY DISPUTE:"
                                outlined
                                dense
                            >
                            </v-text-field>
                        </v-col>
                        <v-col
                            cols="6"
                            sm="6"
                            md="6"
                        >
                            <v-text-field
                                v-model="sales_commission"
                                label="SALES COMMISSION:"
                                outlined
                                dense
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            cols="6"
                            sm="6"
                            md="6"
                        >
                            <input type="hidden" :value=thirteenth_month_pay>
                            <v-btn 
                                block 
                                x-medium
                                color="primary"
                                @click="generateThirteenthMonth"
                            >
                                Generate
                            </v-btn>
                        </v-col>
                        <v-col
                            cols="6"
                        >
                            <v-text-field
                                v-model="total_thirteenth_month_pay"
                                label="13th MONTH PAY:"
                                outlined
                                dense
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-col>    
            </v-row>
            <v-row>
                <v-col
                    cols="6"
                    sm="6"
                    md="3"
                >
                    <v-text-field
                        v-model="hmo"
                        label="HMO:"
                        outlined
                        dense
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="sss_loan"
                        label="SSS LOAN:"
                        outlined
                        dense
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="hdmf_loan"
                        label="HDMF LOAN:"
                        outlined
                        dense
                    >
                    </v-text-field>
                </v-col>
                <v-col
                    cols="6"
                    sm="6"
                    md="3"
                >
                    
                    <v-text-field
                        v-model="phic_retroactive"
                        label="PHIC RETROACTIVE:"
                        outlined
                        dense
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="withholding_tax"
                        label="WITHHOLDING TAX:"
                        outlined
                        dense
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="others"
                        label="OTHERS:"
                        outlined
                        dense
                    >
                    </v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    sm="12"
                    md="6"
                >
                    <v-row>
                        <v-col
                            cols="6"
                        >
                            <v-text-field
                                v-model="total_compensation_deduction"
                                label="TOTAL COMPENSATION DEDUCTIONS:"
                                class="font-weight-black"
                                color="#000000"
                                disabled
                            >
                            </v-text-field>
                        </v-col>
                        <v-col
                            cols="6"
                        >
                            <v-text-field
                                v-model="total_compensation"
                                label="TOTAL TAXABLE COMPENSATION"
                                class="font-weight-black"
                                color="#000000"
                                disabled
                            >
                            </v-text-field>
                        </v-col>
                        <v-col
                            cols="6"
                        >
                            <v-text-field
                                v-model="gross_salary"
                                label="GROSS SALARY:"
                                class="font-weight-black"
                                color="#000000"
                                disabled
                            >
                            </v-text-field>
                        </v-col>
                        <v-col
                            cols="6"
                        >
                            <v-text-field
                                v-model="total_deduction"
                                label="TOTAL DEDUCTIONS:"
                                class="font-weight-black"
                                color="#000000"
                                disabled
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-text-field
                        v-model="take_home_pay"
                        label="TOTAL NET SALARY:"
                        class="total-salary-label-color font-weight-black"
                        color="#000000"
                        disabled
                    >
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col
                    cols="6"
                >
                    <v-text-field
                        v-model="prepared_by"
                        label="Prepared by:"
                        readonly
                    >
                    </v-text-field>
                </v-col>
                <v-col
                    cols="6"
                >
                    <v-text-field
                        v-model="received_by"
                        label="Received by:"
                        readonly
                    >
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-alert
                        type="success"
                        :value = "alert"
                        class="text-center"
                    >
                        {{success}}
                    </v-alert>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-btn 
                        block 
                        x-large
                        color="primary"
                        @click="addPayroll"
                    >
                        Create Payroll
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>
    </v-container>
</template>

<style>
.theme--light.v-input--is-disabled input {
	color: rgba(0, 0, 0, 1);
}

.theme--light.v-label--is-disabled {
	color: rgba(0, 0, 0, 1);
}

.total-salary-label-color .v-label{
    color: #175EAC !important;
}
</style>

<script>
    import BackBtn from '../components/BackBtn'
    import axios from 'axios'
   
    export default {
        name: 'PayrollForm',

        components: {
            BackBtn
        },

        data: vm => ({
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            dateFormatted: vm.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),
            menu1: false,
            dates: [(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10), (new Date(Date.now() - (new Date()).getTimezoneOffset() * 300000)).toISOString().substr(0, 10)],
            dateRangeFormatted: [vm.formatDateRange((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)), vm.formatDateRange((new Date(Date.now() - (new Date()).getTimezoneOffset() * 300000)).toISOString().substr(0, 10)) ],
            menu2: false,

            employee_id: 0,   
            fullname: '',
            id_number: '',
            tin_no: 0,
            sss_no: 0,
            phic_no: 0,
            hdmf_no: 0,
            pay_date: '',
            civil_status: '',
            department: '',
            sub_department_id: 0,
            position: '',
            basic_pay: 0,
            allowances: 0,
            rice_allowance: 0,
            laundry_allowance: 0,
            uniform_allowance: 0,
            transportation_allowance: 0,
            allowance: 0,
            ot: 0,
            ord_ot: 0,
            paid_leaves: 0,
            other_earnings: 0,
            sss_loan: 0,
            hdmf_loan: 0,
            others: 0,
            sss: 0,
            phic: 0,
            hdmf_pagibig: 0,
            phic_retroactive: 0,
            hmo: 0,
            salary_dispute: 0,
            sales_commission: 0,
            total_thirteenth_month_pay: 0,
            prepared_by: '',
            received_by: '',
            attendance: '',
            rangeAttendanceLength: '',
            attLogs: [],
            rangeAttendanceLogs: [],
            employee_no_of_undertime: '',
            employee_undertime: [],
            newUndertime: '',
            newTotal_deduction: '',
            workshift_in: '',
            alert: false,
            success: '',
            numberOfHolidays: 0,
            numberOfHolidaysByEmployeeId: 0,
            matchResult: '',
            holidays: [],
            regHoliday: 0,
            specHoliday: 0,
            holiday_type1: 0,
            holiday_type2: 0,
            totalAbsences: 0,
            numberofPaidLeaves: 0,
            shiftHour: 0
        }),

        computed: {
            computedDateFormatted () {
                return this.formatDate(this.date)
            },

            dateRangeText () {
                let result = this.dates.map(resd => this.formatDateRange(resd));
                return result.join(' - ')
            },

            dateRangeCovered: {
                get: function () {
                    let result = this.dates.map(resd => this.formatDateRange(resd));
                    return result.join(' - ')
                }
            },

            isDisabled() {
                if(this.basic_pay == '0') {
                    return true;
                }else{
                    return false
                }
            },

            total_compensation: {
                get: function () {
                    if(this.basic_pay == '0') {
                        return this.total_thirteenth_month_pay;
                    }else{
                        return this.getTotalCompensation();
                    }
                },
                set: function () {}
            },

            gross_salary: {
                get: function () {
                    if(this.basic_pay == '0') {
                        return this.total_thirteenth_month_pay;
                    }else{
                        return this.getGrossSalary();
                    }
                },
                set: function () {}
            },

            total_compensation_deduction: {
                get: function () {
                    return this.getTotalCompensationDeduction();
                },
                set: function () {}
            },

            total_deduction: {
                get: function() {
                    return this.getTotalDeduction();
                },
                set: function(newVal) {
                    return  parseFloat(newVal);
                }
            },

            loan_tax_other_deduction () {
                let loan_tax_other_deduction = parseFloat(this.hmo) + parseFloat(this.sss_loan) + parseFloat(this.hdmf_loan) 
                        + parseFloat(this.phic_retroactive) + parseFloat(this.withholding_tax) + parseFloat(this.others);

                return loan_tax_other_deduction;
            },

            take_home_pay () {
                let grossSalary = parseFloat(this.gross_salary) - parseFloat(this.loan_tax_other_deduction);
                let takeHomePay = parseFloat(this.allowances) + grossSalary; 
                return takeHomePay.toFixed(2);
            },

            absences:  {
                get: function() {
                    return this.getAbsences();
                }
            },

            tardiness: {
                get: function() {
                    return this.dailyAttendance();
                },
                set: function() {
                    let tardiness = '0.00';
                    return tardiness;
                }
            },

            undertime: {
                get: function () {
                    return this.getUndertime();
                },
                set: function (val) {
                    let undertime = parseFloat(val);
                    return undertime;
                }
            },
            
            daily_rate: {
                get: function() {
                    return this.getDailyRate();
                },
                set: function() {
                    let dailyRate = 0;
                    return dailyRate;
                }
            },

            ord_nd: {
                get: function() {
                    return this.getNightShiftDifferential();
                }
            },

            totalAllowance: {
                get: function () {
                    return this.getTotalAllowance();
                },
                set: function () {}
            },

            thirteenth_month_pay: {
                get: function () {
                    return this.thirteenthMonthPay();
                }
            },

            holiday_pay: {
                get: function () {
                    return this.getRegularHoliday();
                }
            },

            special_holiday_pay: {
                get: function () {
                    return this.getSpecialHoliday();
                }
            },

            withholding_tax: {
                get: function () {
                    return this.getWithholdingTax();
                },
                set: function () {}
            },
        },

        watch: {
            // eslint-disable-next-line no-unused-vars
            date (val) {
                this.dateFormatted = this.formatDate(this.date)
                this.updateDateCovered(this.dateFormatted);
                this.getLoanContribution(this.dateFormatted);
            },

            // eslint-disable-next-line no-unused-vars
            dates (val) {
                this.dateRangeFormatted = this.formatDateRange(this.dates)
            },

            alert (val) {
                if (!val) return

                setTimeout(() => (this.alert = false), 3000)
            },

            basic_pay (val) {
                if(val == 0 ) {
                    this.sss_loan = 0;
                    this.hdmf_loan = 0;
                    this.others = 0;
                    this.sss = 0;
                    this.phic = 0;
                    this.hdmf_pagibig = 0;
                    this.withholding_tax = 0;
                    this.hmo = 0;
                }else{
                    this.total_thirteenth_month_pay = 0;
                }
            },
        },


        methods: {
            formatDate (date) {
                if (!date) return null

                const [year, month, day] = date.split('-')
                return `${month}/${day}/${year}`
            },
            parseDate (date) {
                if (!date) return null

                const [month, day, year] = date.split('/')
                return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
            },

            formatDateRange (date) {
                if (!date) return null

                const [year, month, day] = date.toString().split('-')
                return `${month}/${day}/${year}`
            },
            parseDateRange (date) {
                if (!date) return null
                
                const [month, day, year] = date.toString().split('/')
                return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
            },

            getTotalAllowance () {
                let totalAllowance = parseFloat(this.rice_allowance) + parseFloat(this.laundry_allowance) + parseFloat(this.uniform_allowance)
                                    + parseFloat(this.transportation_allowance);
                
                return totalAllowance;
            },

            getDatesInRange(startDate, endDate) {
                const date = new Date(startDate.getTime());
                const dates = [];

                while (date <= endDate) {
                    dates.push(new Date(date));
                    date.setDate(date.getDate() + 1);
                }
                
                return dates;
            },

            updateDateCovered(date) {
                const dateOffset5 = 4*24*60*60*1000;
                let currentDate = new Date(date);
                let currentDay = currentDate.getDate();
                let fifteenthDay = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 26);
                let twentyeightDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 11);
                let twentyninthDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 11);
                let thirtiethDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 11);
                let thirtyfirstDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 11);

                if(currentDay == 15) {
                    this.dates = [(new Date(new Date(fifteenthDay) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10), (new Date(new Date(date) - (new Date()).getTimezoneOffset() - dateOffset5)).toISOString().substr(0, 10)];
                } else if(currentDay == 28) {
                    this.dates = [(new Date(new Date(twentyeightDay) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10), (new Date(new Date(twentyeightDay) - (new Date()).getTimezoneOffset() * 2600000)).toISOString().substr(0, 10)];
                } else if(currentDay == 29) {
                    this.dates = [(new Date(new Date(twentyninthDay) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10), (new Date(new Date(twentyninthDay) - (new Date()).getTimezoneOffset() * 2600000)).toISOString().substr(0, 10)];
                } else if(currentDay == 30) {
                    this.dates = [(new Date(new Date(thirtiethDay) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10), (new Date(new Date(thirtiethDay) - (new Date()).getTimezoneOffset() * 2600000)).toISOString().substr(0, 10)];
                } else if(currentDay == 31) {
                    this.dates = [(new Date(new Date(thirtyfirstDay) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10), (new Date(new Date(thirtyfirstDay) - (new Date()).getTimezoneOffset() * 2600000)).toISOString().substr(0, 10)];
                } else {
                    this.dates = [(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10), (new Date(Date.now() - (new Date()).getTimezoneOffset() * 300000)).toISOString().substr(0, 10)];
                }
                
                return this.dates;
            },

            getAbsences() {
                let result = this.dates.map(resd => this.formatDateRange(resd));
                let startDate = new Date(result[0]);
                let endDate = new Date(result[1]);
                let startDateRange = new Date(this.formatDate(this.parseDate(result[0])));
                let endDateRange = new Date(this.formatDate(this.parseDate(result[1])));
                let differenceInTime = endDate.getTime() - startDate.getTime();
                let differenceInDays = differenceInTime / (1000 * 3600 * 24) + 1;
                let totalWeekends = 0;

                const data = JSON.parse(localStorage.getItem("tokenData"));
                const userID = JSON.parse(localStorage.getItem("userID"));

                for(let i = startDate; i <= endDate; i.setDate(i.getDate()+1)) {
                    if(i.getDay() == 0 || i.getDay() == 6)
                    totalWeekends++
                }

                let startDateRangeStr = JSON.stringify(startDateRange);
                let startDateRangeParse= new Date(JSON.parse(startDateRangeStr));
                let endDateRangeStr = JSON.stringify(endDateRange);
                let endDateRangeParse= new Date(JSON.parse(endDateRangeStr));

                let tzoffsetstart = (new Date()).getTimezoneOffset() * 60000;
                let newStartDateRange = (new Date(startDateRangeParse - tzoffsetstart)).toISOString().slice(0, 10);
                let tzoffsetend = (new Date()).getTimezoneOffset() * 60000;
                let newEndDateRange = (new Date(endDateRangeParse - tzoffsetend)).toISOString().slice(0, 10);
                
                if(this.sub_department_id !== 0) {
                    this.getHoliday(this.sub_department_id, newStartDateRange, newEndDateRange);
                    this.getHolidayByEmployeeId(this.sub_department_id, userID, newStartDateRange, newEndDateRange);
                }
                
                let totalDays = differenceInDays - totalWeekends;
                let absences = 0;
                let daysAbsent = 0;
                let countMatch = 1;
                let dateMatched = 0;
                let dateMatchHoliday = 0;

                axios
                    .get(process.env.VUE_APP_ROOT_API + '/api/leaveofabsence/' + userID + '/'+ newStartDateRange + '/' + newEndDateRange, {
                        headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization': `Bearer ${data.access_token}`
                                }
                    })
                    .then(response => {
                        this.numberofPaidLeaves = response.data;
                    })
                    .catch(error => {
                        console.log(error)
                    })

                axios
                    .get(process.env.VUE_APP_ROOT_API + '/api/rangeattendance/' + userID + '/'+ newStartDateRange + '/' + newEndDateRange, {
                        headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization': `Bearer ${data.access_token}`
                                }
                    })
                    .then(response => {
                        for(let i = 0; i < response.data.length; i++) {
                            if(response.data[i].att_date) { 
                                dateMatched = countMatch ++;
                                console.log(dateMatched + " dateMatched")
                            }
                        }

                        dateMatchHoliday = dateMatched + this.numberOfHolidays + this.numberOfHolidaysByEmployeeId;

                        if(differenceInDays == 16) {
                            if(totalDays > dateMatched) {
                                daysAbsent = totalDays - dateMatchHoliday;
                                absences = (daysAbsent - this.numberofPaidLeaves) * this.daily_rate;
                            }else if(totalDays == dateMatched){
                                daysAbsent = totalDays - dateMatched;
                                absences = (daysAbsent - this.numberofPaidLeaves) * this.daily_rate;
                            }
                        }else if(differenceInDays == 15) {
                            if(totalDays > dateMatched) {
                                daysAbsent = totalDays - dateMatchHoliday;
                                absences = (daysAbsent - this.numberofPaidLeaves) * this.daily_rate;
                            }else if(totalDays == dateMatched){
                                daysAbsent = totalDays - dateMatched;
                                absences = (daysAbsent - this.numberofPaidLeaves) * this.daily_rate;
                            }
                        }else if(differenceInDays == 13) {
                            if(totalDays > dateMatched) {
                                daysAbsent = totalDays - dateMatchHoliday;
                                absences = (daysAbsent - this.numberofPaidLeaves) * this.daily_rate;
                                
                            }else if(totalDays == dateMatched){
                                daysAbsent = totalDays - dateMatched;
                                absences = (daysAbsent - this.numberofPaidLeaves) * this.daily_rate;
                            }
                        }else{
                            absences = "0";
                        }

                        this.totalAbsences = absences;
                    })
                    .catch(error => {
                        console.log(error)
                    })

                return this.totalAbsences;
            },

            getRangeAttendance (startDate, endDate) {
                const data = JSON.parse(localStorage.getItem("tokenData"));
                const userID = JSON.parse(localStorage.getItem("userID"));

                axios
                    .get(process.env.VUE_APP_ROOT_API + '/api/rangeattendance/' + userID + '/'+ startDate + '/' + endDate, {
                        headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization': `Bearer ${data.access_token}`
                                }
                    })
                    .then(response => {
                        this.rangeAttendanceLength = response.data.length
                        this.rangeAttendanceLogs = response.data;
                    })
                    .catch(error => {
                        console.log(error)
                    })

            },

            getTotalWorkingHours () {
                let attLogsArr = this.attLogs;
                let daysPresent = this.attendance;
                let timeIn = '';
                let timeOut = '';
                let result = this.dates.map(resd => this.formatDateRange(resd));
                let fromDate = this.formatDate(this.parseDate(result[0]));
                let toDate = this.formatDate(this.parseDate(result[1]));
                let [hrsIn, minsIn] = '';
                let [hrsOut, minsOut] = '';
                let totalHoursIn = '';
                let totalHoursOut = '';
                let roundTotalHoursIn = '';
                let roundTotalHoursOut = '';
                let shiftHours = 0;
                let nightWorkingHours = 0;
                let totalNightShiftHours = [];
                let totalNightHours = 0;
                let totalNightWorkingHours = 0;

                for(let i = 0; i < daysPresent; i++) {
                    if(this.formatDate(attLogsArr[i].att_date) <= toDate && this.formatDate(attLogsArr[i].att_date) >= fromDate ) { 
                        timeIn = attLogsArr[i].time_in;
                        timeOut = attLogsArr[i].time_out;
                        [hrsIn, minsIn] = timeIn.split(":");
                        [hrsOut, minsOut] = timeOut.split(":");

                        totalHoursIn = parseInt(hrsIn) + parseFloat(minsIn / 60);
                        totalHoursOut = parseInt(hrsOut) + parseFloat(minsOut / 60);

                        roundTotalHoursIn = Math.ceil(totalHoursIn);
                        roundTotalHoursOut = Math.floor(totalHoursOut);

                        shiftHours = roundTotalHoursOut - roundTotalHoursIn;
                        this.shiftHour = shiftHours;

                        if(Math.ceil(roundTotalHoursIn) == 9) {
                            let newNightShiftStartHour = 0;
                            let nightShiftEndHour = totalHoursOut;
                            let newNightShiftEndHour = 0;

                            if(totalHoursIn <= 9) {
                                newNightShiftStartHour = 9 + 1;
                            }else{
                                newNightShiftStartHour = totalHoursIn + 1;
                            }

                            if(nightShiftEndHour < 18) {
                                newNightShiftEndHour = nightShiftEndHour;
                            }else{
                                newNightShiftEndHour = 18;
                            }

                            nightWorkingHours = newNightShiftEndHour - parseInt(newNightShiftStartHour);
                            if(nightWorkingHours < 8) {
                                totalNightWorkingHours = nightWorkingHours;
                            }else{
                                totalNightWorkingHours = parseInt(nightWorkingHours);
                            }
                            totalNightShiftHours.push(totalNightWorkingHours);
                        }
                        
                        if(Math.ceil(roundTotalHoursIn) == 10) {
                            let newNightShiftStartHour = 0;
                            let nightShiftEndHour = totalHoursOut;
                            let newNightShiftEndHour = 0;

                            if(totalHoursIn <= 10) {
                                newNightShiftStartHour = 10;
                            }else{
                                newNightShiftStartHour = totalHoursIn;
                            }

                            if(nightShiftEndHour < 19) {
                                newNightShiftEndHour = nightShiftEndHour - 1;
                            }else{
                                newNightShiftEndHour = 19 - 1;
                            }
                            
                            nightWorkingHours = newNightShiftEndHour - newNightShiftStartHour;

                            if(nightWorkingHours < 8) {
                                totalNightWorkingHours = nightWorkingHours;
                            }else{
                                totalNightWorkingHours = parseInt(nightWorkingHours);
                            }
                            totalNightShiftHours.push(totalNightWorkingHours);
                        }
                    }
                }

                totalNightShiftHours.forEach( value => {
                    totalNightHours += value;
                });

                return totalNightHours;
            },

            dailyAttendance () {
                let attLogsArr = this.attLogs;
                let timeIn = '';
                let timeOut = '';
                let [hrsIn, minsIn] = '';
                let [hrsOut, minsOut] = '';
                let totalHoursIn = '';
                let totalHoursOut = '';
                let addHours = '';
                let minusHours = '';
                let getMins = '';
                let totalMins = '';
                let totalWorkHours = [];
                let minusMins = '';
                let firstBreakIn, firstBreakOut = 0;
                let secondBreakIn, secondBreakOut = 0;
                let lunchBreakIn, lunchBreakOut = 0;
                let timeInShift = 0;
                let timeInLate = [];
                let timeInTotalShift = 0;
                let firstBreak = 0;
                let firstOverBreak = [];
                let firstTotalOverBreak =  0;
                let secondBreak = 0;
                let secondOverBreak = [];
                let secondTotalOverBreak =  0;
                let lunchBreak = 0;
                let lunchOverBreak = [];
                let lunchTotalOverBreak = 0;
                let allOverBreak = '';
                let sumOverBreak = [];
                let result = this.dates.map(resd => this.formatDateRange(resd));
                let daysPresent = this.attendance;
                let fromDate = this.formatDate(this.parseDate(result[0]))
                let toDate = this.formatDate(this.parseDate(result[1]))

                for(let i = 0; i < daysPresent; i++) {
                    if(this.formatDate(attLogsArr[i].att_date) <= toDate && this.formatDate(attLogsArr[i].att_date) >= fromDate ) { 
                        timeIn = attLogsArr[i].time_in;
                        timeOut = attLogsArr[i].time_out;
                        firstBreakIn = attLogsArr[i].first_break_in;
                        firstBreakOut = attLogsArr[i].first_break_out;
                        secondBreakIn = attLogsArr[i].second_break_in;
                        secondBreakOut = attLogsArr[i].second_break_out;
                        lunchBreakIn = attLogsArr[i].lunch_break_in;
                        lunchBreakOut = attLogsArr[i].lunch_break_out;

                        timeInShift = this.getTimeIn(timeIn, timeOut);
                        timeInLate.push(timeInShift);

                        firstBreak = this.minuteBreak(firstBreakIn, firstBreakOut);
                        firstOverBreak.push(firstBreak);

                        secondBreak = this.minuteBreak(secondBreakIn, secondBreakOut);
                        secondOverBreak.push(secondBreak);

                        lunchBreak = this.lunchBreak(lunchBreakIn, lunchBreakOut);
                        lunchOverBreak.push(lunchBreak);

                        [hrsIn, minsIn] = timeIn.split(":");
                        [hrsOut, minsOut] = timeOut.split(":");

                        totalHoursIn = parseInt(hrsIn) + parseFloat(minsIn / 60);
                        totalHoursOut = parseInt(hrsOut) + parseFloat(minsOut / 60);

                        addHours = totalHoursIn + totalHoursOut;
                        minusHours = Math.abs(addHours - 24);

                        getMins = minusHours - parseInt(minusHours);
                        totalMins = Math.round(getMins * 60) / 100;
                        
                        if(parseInt(hrsIn) !== parseInt(hrsOut) && parseInt(hrsIn) !== 0 && parseInt(hrsOut) !== 0) {
                            let totalWorkHoursMins = parseInt(minusHours) + totalMins;
                            totalWorkHours.push(totalWorkHoursMins.toFixed(2));
                        }else if(parseInt(hrsIn) === parseInt(hrsOut) && parseInt(hrsIn) !== 0 && parseInt(hrsOut) !== 0){
                            minusMins = parseFloat(minsOut - minsIn);
                            totalMins = minusMins / 100;
                            totalWorkHours.push(0.00 + totalMins);
                        }else{
                            totalWorkHours.push(0.00)
                        }
                    }
                }

                timeInLate.forEach(value => {
                    timeInTotalShift += parseFloat(value);
                });

                firstOverBreak.forEach(value => {
                    firstTotalOverBreak += parseFloat(value);
                });
                sumOverBreak.push(firstTotalOverBreak.toFixed(2));

                secondOverBreak.forEach(value => {
                    secondTotalOverBreak += parseFloat(value);
                });
                sumOverBreak.push(secondTotalOverBreak.toFixed(2));

                lunchOverBreak.forEach(value => {
                    lunchTotalOverBreak += parseFloat(value);
                });
                sumOverBreak.push(lunchTotalOverBreak.toFixed(2));

                allOverBreak = firstTotalOverBreak + secondTotalOverBreak + lunchTotalOverBreak;

                let hourlyRate = parseFloat(this.daily_rate / 8);
                let minuteRate = hourlyRate / 60;
                let timeInOverBreak = parseFloat(timeInTotalShift) + parseFloat(allOverBreak);
                let tardiness = (minuteRate * timeInOverBreak).toFixed(2);

                return tardiness;
            },

            getTimeIn (timeIn, timeOut) {
                let [hrsIn, minsIn] = '';
                let [hrsOut, minsOut] = '';
                let totalHoursIn = '';
                let totalHoursOut = '';
                let roundTotalHoursIn = '';
                let roundTotalHoursOut = '';
                let shiftHours = 0;
                let timeLate = 0;

                [hrsIn,minsIn] = timeIn.split(":");
                [hrsOut,minsOut] = timeOut.split(":");

                totalHoursIn = parseInt(hrsIn) + parseFloat(minsIn / 60);
                totalHoursOut = parseInt(hrsOut) + parseFloat(minsOut / 60);

                roundTotalHoursIn = totalHoursIn;
                roundTotalHoursOut = Math.floor(totalHoursOut);
                shiftHours = roundTotalHoursOut - roundTotalHoursIn;

                if(shiftHours < 9) {
                    let newHoursIn = 9 - shiftHours;
                    timeLate = parseFloat(newHoursIn * 60); 
                }else{
                    if(this.workshift_in <= parseInt(roundTotalHoursIn) ) {
                        let newHoursIn = parseInt(roundTotalHoursIn) - this.workshift_in;
                        timeLate = parseFloat(newHoursIn * 60) + parseFloat(minsIn);
                    }else{
                        timeLate = 0;
                    }
                    
                }

                return timeLate;
            },

            lunchBreak (breakIn, breakOut) {
                let [hrsIn, minsIn] = '';
                let [hrsOut, minsOut] = '';
                let overBreakMins = 0;
                let totalMinsBreak = '';
                let totalOverBreak = [];

                [hrsIn, minsIn] = breakIn.split(":");
                [hrsOut, minsOut] = breakOut.split(":");
                
                if(parseInt(hrsIn) === parseInt(hrsOut)) {
                    let subractBreakMins = ''; 
                    subractBreakMins = minsIn - minsOut;
                    if(subractBreakMins <= 60) {
                        overBreakMins = 0;
                        totalOverBreak.push(overBreakMins);
                    }else{
                        totalMinsBreak = subractBreakMins - 60;
                        overBreakMins = totalMinsBreak;
                        totalOverBreak.push(overBreakMins)
                    }
                }else{
                    let addBreakMins = ''; 
                    let minutesOut = '';

                    minutesOut = minsOut - 60;
                    addBreakMins = parseInt(minsIn) + parseInt(Math.abs(minutesOut));
                    if(addBreakMins <= 60) {
                        overBreakMins = 0;
                        totalOverBreak.push(overBreakMins)
                    }else{
                        totalMinsBreak = addBreakMins - 60;
                        overBreakMins = totalMinsBreak;
                        totalOverBreak.push(overBreakMins)
                    }
                }
                
                return totalOverBreak;
            },

            minuteBreak (breakIn, breakOut) {
                let [hrsIn, minsIn] = '';
                let [hrsOut, minsOut] = '';
                let overBreakMins = '';
                let totalMinsBreak = '';
                let totalOverBreak = [];

                [hrsIn, minsIn] = breakIn.split(":");
                [hrsOut, minsOut] = breakOut.split(":");

                if(parseInt(hrsIn) === parseInt(hrsOut)) {
                    let subractBreakMins = ''; 
                    subractBreakMins = minsIn - minsOut;
                    if(subractBreakMins <= 15) {
                        overBreakMins = 0;
                        totalOverBreak.push(overBreakMins)
                    }else{
                        totalMinsBreak = subractBreakMins - 15;
                        overBreakMins = totalMinsBreak;
                        totalOverBreak.push(overBreakMins)
                    }
                }else{
                    let addBreakMins = ''; 
                    let minutesOut = '';

                    minutesOut = minsOut - 60;
                    
                    addBreakMins = parseInt(minsIn) + parseInt(Math.abs(minutesOut));
                    if(addBreakMins <= 15) {
                        overBreakMins = 0;
                        totalOverBreak.push(overBreakMins)
                    }else{
                        totalMinsBreak = addBreakMins - 15;
                        overBreakMins = totalMinsBreak;
                        totalOverBreak.push(overBreakMins)
                    }
                }

                return totalOverBreak;
            },

            getHoliday(subDepId, startDate, endDate) {
                const data = JSON.parse(localStorage.getItem("tokenData"));

                axios
                .get(process.env.VUE_APP_ROOT_API + '/api/payrollholiday/'+ subDepId +'/'+ startDate + '/' + endDate, {
                    headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${data.access_token}`
                            }
                })
                .then(response => {
                    this.numberOfHolidays = response.data;
                })
                .catch(error => {
                    console.log(error)
                })
            },

            getHolidayByEmployeeId(subDepId, empId, startDate, endDate) {
                const data = JSON.parse(localStorage.getItem("tokenData"));

                axios
                .get(process.env.VUE_APP_ROOT_API + '/api/payrollholidaybyid/'+ subDepId +'/'+ empId +'/'+ startDate + '/' + endDate, {
                    headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${data.access_token}`
                            }
                })
                .then(response => {
                    this.numberOfHolidaysByEmployeeId = response.data;
                })
                .catch(error => {
                    console.log(error)
                })
            },

            getUndertime() {
                let employee_undertimeArr = this.employee_undertime;
                let result = this.dates.map(resd => this.formatDateRange(resd));
                let daysUndertime = this.employee_no_of_undertime;
                let fromDate = this.formatDate(this.parseDate(result[0]))
                let toDate = this.formatDate(this.parseDate(result[1]))
                let totalUndertime = 0;

                for(let i = 0; i < daysUndertime; i++) {
                    if(this.formatDate(employee_undertimeArr[i].date_of_undertime) <= toDate && this.formatDate(employee_undertimeArr[i].date_of_undertime)  >= fromDate && employee_undertimeArr[i].hr_status == 'approved') {
                        totalUndertime += parseInt(employee_undertimeArr[i].total_hours);
                    }
                }

                let undertimePerMinute = this.daily_rate / 8 / 60;
                let undertimeResult = undertimePerMinute * (totalUndertime * 60);
                
                return undertimeResult.toFixed(2);
            },

            getDailyRate () {
                // Basic Pay x 2 x 12(months) / 261 days
                let dailyRate = parseFloat(this.basic_pay) * 2 * 12 / 261;
                return dailyRate.toFixed(3);
            },

            getNightShiftDifferential () {
                let nightShiftPay = 0;
                let getNightMinute = 0;
                let totalWorkingMinute = 0;
                let nightMinuteRate = 0;
                let nightShiftMinute = 0;
                let totalNightShiftPay = 0;
                let totalWorkingHours = this.getTotalWorkingHours();
                let hourlyRate = parseFloat(this.daily_rate / 8);
                let minuteRate = hourlyRate / 60;
                let nightHourlyRate = hourlyRate * 0.1;

                if(this.workshift_in == 9) {
                    nightShiftPay = nightHourlyRate * parseInt(totalWorkingHours);
                    getNightMinute = totalWorkingHours % 1;
                    totalWorkingMinute = getNightMinute * 60;
                    nightMinuteRate = minuteRate * 0.1;
                    nightShiftMinute = nightMinuteRate * totalWorkingMinute;
                    totalNightShiftPay = nightShiftPay + nightShiftMinute;
                }else if(this.workshift_in == 10){
                    nightShiftPay = nightHourlyRate * totalWorkingHours;
                    totalNightShiftPay = nightShiftPay;
                }
                
                return parseFloat(totalNightShiftPay).toFixed(2);
            },

            getRegularHoliday () {
                let attLogsArr = this.attLogs;
                let daysPresent = this.attendance;
                let result = this.dates.map(resd => this.formatDateRange(resd));
                let startDate = new Date(this.parseDate(result[0])).toISOString().slice(0, 10);
                let endDate = new Date(this.parseDate(result[1])).toISOString().slice(0, 10);
                let fromDate = this.formatDate(this.parseDate(result[0]));
                let toDate = this.formatDate(this.parseDate(result[1]));
                let holiday_pay = 0;
                
                for(let i = 0; i < daysPresent; i++) {
                    if(this.formatDate(attLogsArr[i].att_date) <= toDate && this.formatDate(attLogsArr[i].att_date) >= fromDate ) { 
                        if(attLogsArr[i].att_date) {
                            this.getHolidayType (this.sub_department_id, attLogsArr[i].att_date );
                        }
                    }
                }

                holiday_pay = this.getRegSpecHoliday1(this.sub_department_id, startDate, endDate, this.holiday_type1);

                return holiday_pay;
            },

            getSpecialHoliday () {
                let attLogsArr = this.attLogs;
                let daysPresent = this.attendance;
                let result = this.dates.map(resd => this.formatDateRange(resd));
                let startDate = new Date(this.parseDate(result[0])).toISOString().slice(0, 10);
                let endDate = new Date(this.parseDate(result[1])).toISOString().slice(0, 10);
                let fromDate = this.formatDate(this.parseDate(result[0]));
                let toDate = this.formatDate(this.parseDate(result[1]));
                let special_holiday_pay = 0;

                for(let i = 0; i < daysPresent; i++) {
                    if(this.formatDate(attLogsArr[i].att_date) <= toDate && this.formatDate(attLogsArr[i].att_date) >= fromDate ) { 
                        if(attLogsArr[i].att_date) {
                            this.getHolidayType (this.sub_department_id, attLogsArr[i].att_date );
                        }
                    }
                }

                special_holiday_pay = this.getRegSpecHoliday2(this.sub_department_id, startDate, endDate, this.holiday_type2);
                
                return special_holiday_pay;
            },


            getHolidayType (subDepID, attDate ) {
                const data = JSON.parse(localStorage.getItem("tokenData"));

                axios
                    .get(process.env.VUE_APP_ROOT_API + '/api/holidaytype/' + subDepID + '/' + attDate, {
                        headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization': `Bearer ${data.access_token}`
                                }
                    })
                    .then(response => {
                        if(response.data.holiday_type == 1) {
                            this.holiday_type1 = response.data.holiday_type;
                        }else if(response.data.holiday_type == 2) {
                            this.holiday_type2 = response.data.holiday_type;
                        }
                        
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },

            getRegSpecHoliday1 (subDepID, startDate, endDate, holidayType) {
                const data = JSON.parse(localStorage.getItem("tokenData"));
                
                axios
                    .get(process.env.VUE_APP_ROOT_API + '/api/regspecholiday/' + subDepID + '/' + startDate + '/' + endDate + '/' + holidayType, {
                        headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization': `Bearer ${data.access_token}`
                                }
                    })
                    .then(response => {
                        let numberOfHoliday = response.data;
                        this.regHoliday = numberOfHoliday * this.daily_rate;
                    
                    })
                    .catch(error => {
                        console.log(error)
                    })

                return this.regHoliday;
            },

            getRegSpecHoliday2 (subDepID, startDate, endDate, holidayType) {
                const data = JSON.parse(localStorage.getItem("tokenData"));
                
                axios
                    .get(process.env.VUE_APP_ROOT_API + '/api/regspecholiday/' + subDepID + '/' + startDate + '/' + endDate + '/' + holidayType, {
                        headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization': `Bearer ${data.access_token}`
                                }
                    })
                    .then(response => {
                        let numberOfHoliday = response.data;
                        this.specHoliday = numberOfHoliday * this.daily_rate;
                    })
                    .catch(error => {
                        console.log(error)
                    })

                return this.specHoliday;
            },

            getTotalCompensationDeduction () {
                let totalCompensationDeduction = parseFloat(this.absences) + parseFloat(this.tardiness) + parseFloat(this.undertime)
                    + parseFloat(this.hdmf_pagibig) + parseFloat(this.sss) + parseFloat(this.phic);

                return totalCompensationDeduction;
            },

            getTotalCompensation () {
                let totalCompensation = parseFloat(this.basic_pay) + parseFloat(this.ot) + parseFloat(this.ord_ot) 
                + parseFloat(this.ord_nd) + parseFloat(this.paid_leaves) + parseFloat(this.holiday_pay) + parseFloat(this.special_holiday_pay)
                + parseFloat(this.salary_dispute) + parseFloat(this.sales_commission) - this.total_compensation_deduction;
                
                return totalCompensation.toFixed(2);
            },

            getGrossSalary () {
                let grossSalary = parseFloat(this.total_compensation) + parseFloat(this.totalAllowance) + parseFloat(this.other_earnings);
                return grossSalary.toFixed(2);
            },

            getTotalDeduction () {
                let totalDeduction = parseFloat(this.total_compensation_deduction) + parseFloat(this.loan_tax_other_deduction);
                return totalDeduction;
            },

            precisionRoundMod(number, precision) {
                let factor = Math.pow(10, precision);
                let n = precision < 0 ? number : 0.01 / factor + number;
                return Math.round( n * factor) / factor;
            },

            thirteenthMonthPay() {   
                let thirteenthPay = parseFloat(this.basic_pay) - (parseFloat(this.absences) + parseFloat(this.tardiness) + parseFloat(this.undertime));
                return thirteenthPay;
            },

            generateThirteenthMonth() {
                const data = JSON.parse(localStorage.getItem("tokenData"));
                const userID = JSON.parse(localStorage.getItem("userID"));
                const currentDate = new Date(Date.now());
                const currentMonth = currentDate.getMonth();

                const payDate = new Date(this.dateFormatted);
                const payDateYear = payDate.getFullYear();
                
                if(this.basic_pay == 0) {
                    axios
                        .get(process.env.VUE_APP_ROOT_API + '/api/totalthirteenthmonth/' + userID + '/' + payDateYear, {
                            headers: {
                                        'Content-Type': 'application/json',
                                        'Authorization': `Bearer ${data.access_token}`
                                    }
                        })
                        .then(response => {
                            if(currentMonth != 11) {
                                let totalThirteenthMonth = parseFloat(response.data / 12);
                                return this.total_thirteenth_month_pay = totalThirteenthMonth.toFixed(2);
                            }else{
                                let totalThirteenthMonth = (response.data + parseFloat(this.basic_pay)) / 12;
                                return this.total_thirteenth_month_pay = totalThirteenthMonth.toFixed(2);
                            }
                        })
                        .catch(error => {
                            console.log(error)
                        })
                }
            },

            getWithholdingTax() {
                let currentDate = new Date(this.dateFormatted);
                let currentDay = currentDate.getDate();
                let totalCompensation = this.getTotalCompensation();
                let totalTaxableIncome = totalCompensation + this.sales_commission;
                let totalTaxable = 0;
                let totalWithTax = 0;

                if(currentDay == 15) {
                    totalWithTax = 0;
                }else if(currentDay == 28 || currentDay == 29 || currentDay == 30 || currentDay == 31){
                    if(totalTaxableIncome >= '33333' && totalTaxableIncome <= '83332') {
                        totalTaxable = totalTaxableIncome - 33333;
                        totalWithTax = (totalTaxable * 0.25) + 4270;
                    }else if(totalTaxableIncome >= '16667' && totalTaxableIncome <= '33332') {
                        totalTaxable = totalTaxableIncome - 16667;
                        totalWithTax = (totalTaxable * 0.2) + 937.50;
                    }else if(totalTaxableIncome >= '10417' && totalTaxableIncome <= '16666') {
                        totalTaxable = totalTaxableIncome - 10417;
                        totalWithTax = totalTaxable * 0.15;
                    }else{
                        totalWithTax = 0;
                    }  
                }           
                
                return totalWithTax.toFixed(2);
            },

            getLoanContribution(date) {
                const data = JSON.parse(localStorage.getItem("tokenData"));
                const userID = JSON.parse(localStorage.getItem("userID"));

                let currentDate = new Date(date);
                let currentDay = currentDate.getDate();

                axios
                    .get(process.env.VUE_APP_ROOT_API + '/api/loancontribution/' + userID, {
                        headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization': `Bearer ${data.access_token}`
                                }
                    })
                    .then(response => {
                        if(response.data != "") {
                            if(currentDay == 15) {
                                this.sss_loan = response.data.sss_loan;
                                this.hdmf_loan = response.data.hdmf_loan;
                                this.others = response.data.others;
                                this.sss = response.data.sss_cont;
                                this.phic = response.data.phic_cont;
                                this.hdmf_pagibig = response.data.hdmf_pagibig_cont;
                                this.withholding_tax = 0;
                                this.hmo = 0;
                            }else if(currentDay == 28 || currentDay == 29 || currentDay == 30 || currentDay == 31){
                                this.sss_loan = 0;
                                this.hdmf_loan = 0;
                                this.others = 0;
                                this.sss = 0;
                                this.phic = 0;
                                this.hdmf_pagibig = 0;
                                this.withholding_tax;
                                this.hmo = response.data.hmo;
                            }
                        }
                        
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },

            addPayroll() {
                const data = JSON.parse(localStorage.getItem("tokenData"));

                let payrollData = {
                    employee_id: this.employee_id,
                    fullname: this.fullname,
                    id_number: this.id_number,
                    tin_no: this.tin_no,
                    sss_no: this.sss_no,
                    phic_no: this.phic_no,
                    hdmf_no: this.hdmf_no,
                    pay_date: this.dateFormatted,
                    date_covered: this.dateRangeText,
                    tax_status: this.civil_status,
                    department: this.department,
                    position: this.position,
                    daily_rate: this.daily_rate,
                    basic_pay: this.basic_pay,
                    rice_allowance: this.rice_allowance,
                    laundry_allowance: this.laundry_allowance,
                    uniform_allowance: this.uniform_allowance,
                    transportation_allowance: this.transportation_allowance,
                    allowance: this.totalAllowance,                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         
                    salary_dispute: this.salary_dispute,
                    sales_commission: this.sales_commission,
                    ot: this.ot,
                    ord_ot: this.ord_ot,
                    ord_nd: this.ord_nd,
                    paid_leaves: this.paid_leaves,
                    holiday_pay: this.holiday_pay,
                    special_holiday_pay: this.special_holiday_pay,
                    other_earnings: this.other_earnings,
                    total_compensation: this.total_compensation, 
                    total_compensation_deduction: this.total_compensation_deduction,
                    absences: this.absences,
                    tardiness: this.tardiness,
                    undertime: this.undertime,
                    sss_loan: this.sss_loan,
                    hdmf_loan: this.hdmf_loan,
                    others: this.others,
                    sss: this.sss,
                    phic: this.phic,
                    hdmf_pagibig: this.hdmf_pagibig,
                    phic_retroactive: this.phic_retroactive,
                    withholding_tax: this.withholding_tax,
                    hmo: this.hmo,
                    gross_salary: this.gross_salary,
                    total_deductions: this.total_deduction,
                    thirteenth_month_pay: this.thirteenth_month_pay,
                    total_thirteenth_month_pay: this.total_thirteenth_month_pay,
                    take_home_pay: this.take_home_pay,
                    prepared_by: this.prepared_by,
                    received_by: this.received_by,
                    status: '0',
                }
                axios
                .post(process.env.VUE_APP_ROOT_API + '/api/payroll/add', payrollData, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${data.access_token}`
                    }
                })
                .then(response => {
                    this.alert = true;
                    this.success = response.data.success;
                    setTimeout(() => (this.$router.push('/dashboard')), 4000)
                })
                .catch(error => {
                    console.log(error)
                })
            }
        },

        mounted() {
        const data = JSON.parse(localStorage.getItem("tokenData"));
        const userID = JSON.parse(localStorage.getItem("userID"));
        let result = this.dates.map(resd => this.formatDateRange(resd));
        let startDateRange = new Date(this.formatDate(this.parseDate(result[0])));
        let endDateRange = new Date(this.formatDate(this.parseDate(result[1])));

        let startDateRangeStr = JSON.stringify(startDateRange);
        let startDateRangeParse= new Date(JSON.parse(startDateRangeStr));
        let endDateRangeStr = JSON.stringify(endDateRange);
        let endDateRangeParse= new Date(JSON.parse(endDateRangeStr));

        let tzoffsetstart = (new Date()).getTimezoneOffset() * 60000;
        let newStartDateRange = (new Date(startDateRangeParse - tzoffsetstart)).toISOString().slice(0, 10);
        let tzoffsetend = (new Date()).getTimezoneOffset() * 60000;
        let newEndDateRange = (new Date(endDateRangeParse - tzoffsetend)).toISOString().slice(0, 10);

        axios
        .get(process.env.VUE_APP_ROOT_API + '/api/getemployeeinfo', {
            headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${data.access_token}`
                    }
        })
        .then(response => {
            this.prepared_by = response.data.first_name + " " + response.data.last_name;
        })
        .catch(error => {
            console.log(error)
        })

        axios
        .get(process.env.VUE_APP_ROOT_API + '/api/payroll/employeeinfo/'+ userID, {
            headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${data.access_token}`
                    }
        })
        .then(response => {
            this.employee_id = userID;
            this.id_number = response.data.emp_id
            this.fullname = response.data.first_name + " " + response.data.last_name
            this.civil_status = response.data.civil_status
            this.department = response.data.sub_department_name
            this.sub_department_id = response.data.sub_department_id
            this.position = response.data.position_name
            this.received_by = response.data.first_name + " " + response.data.last_name
        })
        .catch(error => {
            console.log(error)
        })

        axios
            .get(process.env.VUE_APP_ROOT_API + '/api/payroll/requirements/' + userID , {
                headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${data.access_token}`
                        }
            })
            .then(response => {
                this.tin_no = response.data.tin_no
                this.sss_no = response.data.sss_no
                this.phic_no = response.data.phic_no
                this.hdmf_no = response.data.hdmf_no
            })
            .catch(error => {
                console.log(error)
            })

        axios
            .get(process.env.VUE_APP_ROOT_API + '/api/payrollattendance/' + userID, {
                headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${data.access_token}`
                        }
            })
            .then(response => {
                this.attendance = response.data.length;
                this.attLogs = response.data;
            })
            .catch(error => {
                console.log(error)
            })

        
       axios
            .get(process.env.VUE_APP_ROOT_API + '/api/rangeattendance/' + userID + '/' + newStartDateRange + '/' + newEndDateRange, {
                headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${data.access_token}`
                        }
            })
            .then(response => {
                this.rangeAttendanceLength = response.data.length;
                this.rangeAttendanceLogs = response.data;
            })
            .catch(error => {
                console.log(error)
            })

        axios
            .get(process.env.VUE_APP_ROOT_API + '/api/undertime/payroll/' + userID, {
                headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${data.access_token}`
                        }
            })
            .then(response => {
                this.employee_no_of_undertime = response.data.length;
                this.employee_undertime = response.data;
            })
            .catch(error => {
                console.log(error)
            })

        axios
            .get(process.env.VUE_APP_ROOT_API + '/api/payroll/workshift/' + userID, {
                headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${data.access_token}`
                        }
            })
            .then(response => {
                let timeIn = response.data.time;
                let timeInSplit = timeIn.split("-")
                let timeInStart = timeInSplit[0].match(/\d+/)
                this.workshift_in = timeInStart[0];
            })
            .catch(error => {
                console.log(error)
            })
        
        axios
            .get(process.env.VUE_APP_ROOT_API + '/api/basicsalary/' + userID, {
                headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${data.access_token}`
                        }
            })
            .then(response => {
                this.basic_pay = response.data[0].basic_salary;
            })
            .catch(error => {
                console.log(error)
            })
      

        axios
            .get(process.env.VUE_APP_ROOT_API + '/api/loancontribution/' + userID, {
                headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${data.access_token}`
                        }
            })
            .then(response => {
                if(response.data != "") {
                    this.sss_loan = response.data.sss_loan;
                    this.hdmf_loan = response.data.hdmf_loan;
                    this.others = response.data.others;
                    this.sss = response.data.sss_cont;
                    this.phic = response.data.phic_cont;
                    this.hdmf_pagibig = response.data.hdmf_pagibig_cont;
                    this.withholding_tax = response.data.withholding_tax;
                    this.hmo = response.data.hmo;
                }
                
            })
            .catch(error => {
                console.log(error)
            })
        },
        
    }
</script>