import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginView from '../views/LoginView.vue'
import Dashboard from '../views/DashboardView.vue'
import Employees from '../views/EmployeesView.vue'
import Payslip from '../views/PayslipView.vue'
import Payroll from '../views/PayrollView.vue'
import PayrollEdit from '../views/PayrollViewEdit.vue'
import UserPayslip from '../views/UserPayslipView.vue'
import PayrollGenerated from '../views/PayrollGeneratedView.vue'
import GeneratedThirteenthMonth from '../views/GeneratedThirteenthMonthView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: LoginView
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard
  },
  {
    path: '/dashboard/employees',
    name: 'employees',
    component: Employees
  },
  {
    path: '/dashboard/payroll',
    name: 'payroll',
    component: Payroll
  },
  {
    path: '/dashboard/payroll/edit',
    name: 'payrolledit',
    component: PayrollEdit
  },
  {
    path: '/dashboard/payslip',
    name: 'payslip',
    component: Payslip
  },
  {
    path: '/dashboard/payslip/employee',
    name: 'employee',
    component: UserPayslip
  },
  {
    path: '/dashboard/payroll/generated',
    name: 'payrollgenerated',
    component: PayrollGenerated
  },
  {
    path: '/dashboard/payroll/generatedthirteenthmonth',
    name: 'generatedthirteenthmonth',
    component: GeneratedThirteenthMonth
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
