<template>
    <v-container fluid>
        <v-row>
            <v-col class="col-xs-12 col-md-8 col-lg-8" style="background-image: linear-gradient(96deg, rgb(40, 50, 123), rgb(12, 110, 182));background-size: contain;padding: 7%;background-repeat: no-repeat;background-position: left top;">
                <v-img
                :src="require('../assets/blueprint-logo.png')"
                class="my-3"
                max-height="100"
                max-width="179"
                />
                <h1 class="heading white--text text-lg-h1 font-weight-bold">Blueprint <br /> Payroll System</h1>
                <div class="subheading white--text my-lg-2">
                    Please enter your username and password to sign in to your account:
                </div>
                <v-spacer class="my-lg-11">
                </v-spacer>
            </v-col>
            <v-col class="pa-lg-14 col-xs-12 col-md-4 col-lg-4">
                <v-form>
                    <v-container>
                        <v-row>
                            <v-col>
                                <div class="py-5 text-center">
                                    <h2 class="text-lg-h4 text-left font-weight-bold" style="color:rgb(0, 77, 158)">Login</h2>
                                </div>
                                <v-text-field
                                    v-model="login.name"
                                    label="Username"
                                    outlined
                                    placeholder="Username"
                                >
                                </v-text-field>
                                <v-text-field
                                    v-model="login.password"
                                    label="Password"
                                    outlined
                                    placeholder="Password"
                                    :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'"
                                    @click:append="() => (value = !value)"
                                    :type="value ? 'password' : 'text'"
                                    v-on:keyup.enter="loginuser" 
                                >
                                </v-text-field>
                                <!--<v-checkbox
                                    v-model="checkbox"
                                    label="Keep me signed in"
                                    required
                                ></v-checkbox>-->
                                <v-alert
                                    type="error"
                                    :value = "alert"
                                    class="text-center"
                                >
                                    {{errorMessage}}
                                </v-alert>
                                <v-btn
                                    x-large
                                    block
                                    class="py-2"
                                    style="background-color:rgb(0, 77, 158); color:rgb(255, 255, 255);"
                                    @click="loginuser"
                                >
                                    Log-In
                                    <v-icon 
                                        dark
                                        small
                                    >
                                        mdi-login
                                    </v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-col>
            <v-col cols="12" style="background-color: white;">
                <v-footer style="background-color: white;">
                    <v-card-text class="py-2 dark--text text-center">
                        <strong>Blueprint Business Solutions</strong> © 2023 
                    </v-card-text>
                </v-footer>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import axios from 'axios';

  export default {
    data() {
      return {
        login: {
            name: "",
            password: ""
        },
        value: String,
        checkbox: false,
        alert: false,
        errorMessage: '',
      };
    },
    methods: {
        loginuser() {
            axios
            .post(process.env.VUE_APP_ROOT_API + '/api/payrolllogin', this.login, {
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then(response => {
                localStorage.setItem("tokenData", JSON.stringify(response.data));
                this.$router.push('/dashboard')
            })
            .catch(error => {
                this.alert = true;
                this.errorMessage = error.response.data.error;
            })
        }
    },
  };
</script>