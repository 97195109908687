<template>
  <div>
    <v-app-bar
        flat
        fixed
        color="indigo darken-2"
        dark
    >
      <v-app-bar-nav-icon v-if="!permanent" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      
      <v-spacer></v-spacer>
      <v-toolbar-title><div class="text-sm-h6 text-md-h5 text-lg-h4 text-lg-h3">Blueprint Payroll System</div></v-toolbar-title>
      <v-spacer></v-spacer>
      <!---<v-btn icon
        @click="logoutUser"
      >
        <v-icon>mdi-export</v-icon>
      </v-btn>-->
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      temporary
      class="indigo darken-2"
      dark
      height="400"
      app
    >
      <v-list>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          :to="item.link"
          class="indigo darken-2"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <template v-slot:append>
        <div class="pa-2">
          <v-btn 
            block
            @click="logoutUser"
          >
            <v-icon>mdi-export</v-icon>Logout 
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
  import axios from 'axios';
  
  export default {

    data() {
      return {
        drawer: null,
        permanent: false,
        items: [
          { title: 'Dashboard', icon: 'mdi-view-dashboard', link: '/dashboard' },
          { title: 'Generated Payroll', icon: 'mdi-account-credit-card-outline', link: '/dashboard/payroll/generated' },
          { title: 'Generated 13th Month', icon: 'mdi-account-credit-card', link: '/dashboard/payroll/generatedthirteenthmonth' },
        ],
        logout: ''
      }
    },
    
    methods: {
      logoutUser() {
        const data = JSON.parse(localStorage.getItem("tokenData"));
        axios
        .post(process.env.VUE_APP_ROOT_API + '/api/logout', this.logout, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${data.access_token}`
            }
        })
        .then(response => {
          this.logout = response.data;
          this.$router.push('/')
        })
        .catch(error => {
          console.log(error)
        })
      }
    }
  }
</script>