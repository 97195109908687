<template>
    <div>
        <v-container class="mt-16">
            <div class="text-center"><h2>Generated 13th Month Pay</h2></div>
          <v-row>
              <v-col
                cols="12"
                sm="12"
                md="2"
              >
                <v-select
                  v-model="date_selected"
                  :items="payDate"
                  item-text="pay_date"
                  item-value="pay_date"
                  label="Select Pay Date"
                  @change="selectedDate"
                ></v-select>

                <div class="my-2">
                  <v-alert
                        type="success"
                        :value = "alert"
                        class="text-center"
                    >
                    {{success}}
                  </v-alert>
                  <v-btn
                    :color="btnColor"
                    large
                    dark
                    @click="enablePayroll"
                    :disabled="btnDisable"
                  >
                    {{btnValue}}
                  </v-btn>
                </div>

                <div class="my-10">
                  <label>Filter By Year</label>
                  <v-select
                    v-model = "year_selected"
                    :items = "yearList"
                    item-text = "year"
                    item-value = "year"
                    label = "Select Year"
                    @change = "selectedYear"
                    >
                  </v-select> 
                </div>

                <div class="my-2">
                  <v-btn
                    :color="btnColor"
                    large
                    dark
                    @click="downloadThirteenthPay"
                  >
                    Download
                  </v-btn>
                </div>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="9"
              >
                <v-card 
                  min-height= "500px"
                  >
                  <v-data-table
                    :headers="headers"
                    :items="payroll"
                    item-key="uuid"
                  >
                    <template v-slot:item="{ item }">
                      <tr>
                          <td class="indigo--text text--darken-4">{{item.fullname}}</td>
                          <td :id="item.uuid"><v-btn :class="{ 'gray--text': item.status == 0,'green--text': item.status == 1 }" :id="item.uuid" @click="enablePayslip(item.uuid)">{{item.status == statusValue ? payOff:payOn}}</v-btn></td>
                          <!--<td v-if="item.status == 0"><v-btn plain @click="enablePayslip(item.uuid)" :id="item.uuid" :class="{'green--text':isGreen}">{{btnStatusValue}}</v-btn></td>
                          <td v-else><v-btn plain class="green--text">On</v-btn></td>-->
                          <td v-if="item.acknowledge_status == 0"><span class="red--text">Unacknowledged</span></td>
                          <td v-else><span class="green--text">Acknowledged</span></td>
                          <td>₱{{formatField(item.withholding_tax)}}</td>
                          <td>₱{{formatField(item.total_deductions)}}</td>
                          <td>₱{{formatField(item.take_home_pay)}}</td>
                      </tr>
                    </template>
                    <template slot="body.append">
                      <tr class="black--text">
                          <th class="title">Totals</th>
                          <th class="title"></th>
                          <th class="title">{{ sumField('withholding_tax') }}</th>
                          <th class="title">{{ sumField('total_deductions') }}</th>
                          <th class="title">{{ sumField('take_home_pay') }}</th>
                      </tr>
                    </template>
                  </v-data-table>
                </v-card>
            </v-col>
          </v-row>
        </v-container>
    </div>
</template>
<style>
.theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: #4caf50 !important;
}

.theme--dark.v-btn.v-btn--disabled {
  color: #ffffff !important;
}
</style>

<script>
  import axios from 'axios';

  export default {
    name: 'GeneratedThirteenthMonthForm',
    
    data: vm => ({
        search: '',
        headers: [
          {text: 'Full Name', value: 'fullname'},
          {text: 'Enable Status', value: 'status'},
          {text: 'Payslip Status', value: 'acknowledge_status'},
          {text: 'Deductions', value: 'total_deductions'},
          {text: 'Net Pay', value: 'take_home_pay'},
          {text: '13th Month Pay', value: 'thirteenth_month_pay'},
          ],
        employee: [],
        date_selected: '',
        year_selected: '',
        payDate: [],
        payroll: [],
        selectData: null,
        success: '',
        alert: false,
        type: '',
        btnColor: '',
        btnDisable: false,
        btnValue: 'ENABLE PAYSLIP',
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        dateFormatted: vm.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),
        yearMenu: false,
        activePicker: null,
        startYear: 2022,
        endYear: new Date().getFullYear(),
        btnStatusValue: 'Off',
        isGreen: false,
        payOff: 'Off',
        payOn: 'On',
        enableAlert: false,
        statusValue: '0',
        btnTextColor: false,
      }),

    computed: {
        computedDateFormatted () {
            return this.formatDate(this.date)
        },

        yearList() {
          const years = [];
          for (let i = this.endYear; i >= this.startYear; i--) {
            years.push(i);
          }
          return years;
        },

    },

    watch: {
      // eslint-disable-next-line no-unused-vars
      date (val) {
          this.dateFormatted = this.formatDate(this.date.slice(0,4))
      },

      yearMenu (val) {
        val && setTimeout(() => (this.activePicker = this.picker || 'YEAR'))
      },

      alert (val) {
        if (!val) return
        setTimeout(() => (this.alert = false, this.btnDisable = true,
                this.btnValue = 'PAYSLIP ENABLED'), 3000)
      },

      btnStatusValue (val) {
        if(val == 'On') {
          this.isGreen = true;
        }
      },

      enableAlert (val) {
        if (!val) return
        setTimeout(() => (this.enableAlert = false, this.btnDisable = true,
                this.statusValue= '1'), 3000)
      },
    },

    methods: {
      formatDate (date) {
          if (!date) return null

          const [year, month, day] = date.split('-')
          return `${month}/${day}/${year}`
      },

      parseDate (date) {
          if (!date) return null

          const [month, day, year] = date.split('/')
          return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      },

      saveYear(year) {
          this.$refs.yearMenu.save(year)

          // Reset activePicker to type YEAR
          this.activePicker= 'YEAR'

          // Close the menu/datepicker
          this.yearMenu = false
      },

      selectedDate() {
        const data = JSON.parse(localStorage.getItem("tokenData"));

        axios
          .get(process.env.VUE_APP_ROOT_API + '/api/generatedthirteenthmonth/' + this.date_selected, {
            headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${data.access_token}`
                    }
          })
          .then(response => {
              this.payroll = response.data
  
              for(let i = 0; i < response.data.length; i++){
                if(response.data[i].status === '1' ) {
                  this.btnColor = 'success';
                  this.btnDisable = true;
                  this.btnValue = 'PAYSLIP ENABLED';
                  this.btnTextColor = true;
                }else{
                  this.btnColor = 'primary';
                  this.btnDisable = false;
                  this.btnValue = 'ENABLE PAYSLIP';
                  this.btnTextColor = false;
                }
              }
              
          })
          .catch(error => {
              console.log(error)
          })
      },

      selectedYear() {
        const data = JSON.parse(localStorage.getItem("tokenData"));
        this.btnStatusValue = 'Off';
        this.isGreen = false;

        axios
          .get(process.env.VUE_APP_ROOT_API + '/api/generatedthirteenthmonthbyyear/' + this.year_selected, {
            headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${data.access_token}`
                    }
          })
          .then(response => {
              this.payroll = response.data
          })
          .catch(error => {
              console.log(error)
          })
      },

      enablePayroll() {
        this.btnStatusValue = "On";
        const data = JSON.parse(localStorage.getItem("tokenData"));
        let payrollData = {
                status: '1',
        }
        axios
        .post(process.env.VUE_APP_ROOT_API + '/api/payroll/generate/' +  this.date_selected, payrollData, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${data.access_token}`
            }
        })
        .then(response => {
            if(response.data.success) {
              this.alert = true;
              this.success = response.data.success;
              this.statusValue = "On";
              this.btnTextColor = true;
            }else{
              console.log("Already Enabled!");
            }
        })
        .catch(error => {
            console.log(error)
        })
      },

      enablePayslip(uuid) {
        const data = JSON.parse(localStorage.getItem("tokenData"));
        let payrollData = {
                status: '1',
        }
        axios
        .post(process.env.VUE_APP_ROOT_API + '/api/payslip/enable/' +  uuid, payrollData, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${data.access_token}`
            }
        })
        .then(response => {
            if(response.data.success) {
              this.selectedDate(); 
            }else{
              console.log("Already Enabled!");
            }
        })
        .catch(error => {
            console.log(error)
        })
      },

      formatField(key) {
        let result = parseFloat(key)
        return result.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                });
      },

      sumField(key) {
        // sum data in give key (property)
        let totals = this.payroll.reduce((a, b) => parseFloat(a) + parseFloat((b[key] || 0)), 0)
        return '₱' + totals.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })
      },

      downloadThirteenthPay() {
        const payDate = this.date_selected.split('/').join('-');
        const data = JSON.parse(localStorage.getItem("tokenData"));
        axios
        .get(process.env.VUE_APP_ROOT_API + '/api/exportthirteenthpay/' + this.date_selected, {
            responseType: 'blob',
            headers: {
                'Authorization': `Bearer ${data.access_token}`
            }
        })
        .then(response => {
            const url = URL.createObjectURL(
              new Blob([response.data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              })
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "thirteenthpay-" + payDate + ".xlsx");
            document.body.appendChild(link);
            link.click();
        })
        .catch(error => {
            console.log(error);
        })
      },
    },
    
    mounted() {
      const data = JSON.parse(localStorage.getItem("tokenData"));
      axios
      .get(process.env.VUE_APP_ROOT_API + '/api/payroll', {
        headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${data.access_token}`
                }
      })
      .then(response => {
          let resultDate = '';
          let resultDateArray = [];
          for(let i = 0; i < response.data.length; i++) {
            if(response.data[i].basic_pay == 0) {
              resultDate = response.data[i];
              resultDateArray.push(resultDate);
            }
          }
          this.payDate = resultDateArray;
          this.employee = response.data;
      })
      .catch(error => {
          console.log(error)
      })
    },
  }
</script>