<template>
  <v-container class="mt-16">
    <v-row>
        <v-col
          cols="12"
        >
        <div class="text-center"><h2>Search Employee</h2></div>
        <template>
          <v-card 
            min-height= "500px"
            >
            <v-card-title>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :headers="headers"
              :items="employee"
              :search="search"
              @click:row="userClick"
            >
              <v-container v-for="empl in employee" :key="empl.emp_id" fluid>
                <div >empl</div>
              </v-container >
            </v-data-table>
          </v-card>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import axios from 'axios';
  //import DashboardNav from '../components/DashboardNav'

  export default {
    name: 'DashboardHome',

    /*components: {
      DashboardNav,
    },*/
    
    data() {
      return {
        search: '',
        headers: [
          {text: 'Employee ID', value: 'emp_id'},
          {text: 'Full Name', value: 'full_name'},
          {text: 'Position', value: 'position_name'},
          {text: 'Department', value: 'department_name'},
          {text: 'Address', value: 'current_address'},
          ],
        employee: [],
      }
    },

    methods: {
      userClick(row) {
        localStorage.setItem("userID", JSON.stringify(row.id));
        localStorage.setItem("empID", JSON.stringify(row.emp_id));
        localStorage.setItem("user_id", JSON.stringify(row.user_id));
        this.$router.push('/dashboard/payslip/employee');
      }
    },
    
    mounted() {
      const data = JSON.parse(localStorage.getItem("tokenData"));
      axios
      .get(process.env.VUE_APP_ROOT_API + '/api/payroll/employees', {
        headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${data.access_token}`
                }
      })
      .then(response => {
        this.employee = response.data
      })
      .catch(error => {
        console.log(error)
      })
    },
  }
</script>