<template>
  <div>
    <DashboardHeader />
    <PayrollForm />
    <DashboardFooter />
  </div>
</template>

<script>
  import DashboardHeader from '../components/DashboardHeader'
  import PayrollForm from '../components/PayrollForm'
  import DashboardFooter from '../components/DashboardFooter'

  export default {
    name: 'PayrollView',

    components: {
      DashboardHeader,
      PayrollForm,
      DashboardFooter
    },
  }
</script>
