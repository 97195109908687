<template>
  <v-container class="mt-16">
    <back-btn></back-btn>
    <h2 class="text-center">Employee Payslips</h2>
    <p class="text-center mt-5 mb-0">{{this.employee_name}}</p>
    <p class="text-center mt-0"><small>{{this.employee_id}}</small></p>
    <template>
        <v-card 
            min-height= "500px"
            >
            <!--<v-simple-table
                fixed-header
                height="200px"
            >
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th></th>
                            <th class="text-left">
                                Pay Date
                            </th>
                            <th class="text-left">
                                View / Edit
                            </th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                        v-for="item in payslip"
                        :key="item.id"
                        >
                            <td></td>
                            <td>{{ item.pay_date }}</td>
                            <td>
                                <tr>
                                    <td>
                                        <div class="back-btn">
                                            <v-btn icon
                                                @click="viewButton(item)"
                                            >
                                                <v-icon>mdi-file-eye-outline</v-icon>
                                            </v-btn>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="back-btn">
                                            <v-btn icon
                                                @click="editButton(item)"
                                            >
                                                <v-icon>mdi-file-document-edit-outline</v-icon>
                                            </v-btn>
                                        </div>
                                    </td>
                                </tr>
                            </td>
                            <td></td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
            <v-card-text class="text-center">
                {{notfound}}
            </v-card-text>
            <v-card-actions class="justify-center">
                    <v-btn 
                        color="primary"
                        x-large
                        @click="addPayroll"
                        class="back-btn"
                    >
                        <v-icon>mdi-file-document-plus-outline</v-icon>
                        Create Payroll
                    </v-btn>
            </v-card-actions>-->
            <v-card-title>
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Search"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-card-title>
            <v-data-table
                :search="search"
                :headers="headers"
                :items="payslip"
                >
                <template v-slot:[`item.action`]="{ item }">
                     <v-btn icon
                        @click="viewButton(item)"
                    >
                        <v-icon>mdi-file-eye-outline</v-icon>
                    </v-btn>
                    <v-btn icon
                        @click="editButton(item)"
                    >
                        <v-icon>mdi-file-document-edit-outline</v-icon>
                    </v-btn>
                </template>
            </v-data-table>
            <v-card-actions class="justify-center">
                    <v-btn 
                        color="primary"
                        x-large
                        @click="addPayroll"
                        class="back-btn"
                    >
                        <v-icon>mdi-file-document-plus-outline</v-icon>
                        Create Payroll
                    </v-btn>
            </v-card-actions>
        </v-card>
    </template>
  </v-container>
</template>

<script>
  import BackBtn from '../components/BackBtn'
  import axios from 'axios';
  
  export default {

    data() {
      return {
        notfound: '',
        employee_name: '',
        employee_id: '',
        search: '',
        headers: [
          {text: 'Pay Date', value: 'pay_date'},
          {text: 'View / Edit', value: 'action'},
          ],
        payslip: [],
      }
    },

    components: {
        BackBtn
    },
    
    methods: {
      viewButton: function (item) {
        localStorage.setItem("payrollID", JSON.stringify(item.id));
        this.$router.push('/dashboard/payslip');
      },
      editButton(item) {
        localStorage.setItem("payrollID", JSON.stringify(item.id));
        this.$router.push('/dashboard/payroll/edit');
      },
      addPayroll() {
        this.$router.push('/dashboard/payroll');
      }
    },

    mounted() {
        const data = JSON.parse(localStorage.getItem("tokenData"));
        const userID = JSON.parse(localStorage.getItem("userID"));

        axios
        .get(process.env.VUE_APP_ROOT_API + '/api/payroll/'+ userID, {
            headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${data.access_token}`
                    }
        })
        .then(response => {
            if(response.data.length !== 0) {
                this.payslip = response.data;
                this.employee_name = response.data[0].fullname
                this.employee_id = response.data[0].id_number
            }else{
                this.notfound = "No Data Found!";
            }
            
        })
        .catch(error => {
            console.log(error)
        })
    },
  }
</script>