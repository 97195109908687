<template>
  <div>
    <DashboardHeader />
    <PayslipForm />
    <DashboardFooter />
  </div>
</template>

<script>
  import DashboardHeader from '../components/DashboardHeader'
  import PayslipForm from '../components/PayslipForm'
  import DashboardFooter from '../components/DashboardFooter'

  export default {
    name: 'PayslipView',

    components: {
      DashboardHeader,
      PayslipForm,
      DashboardFooter
    },
  }
</script>
