<template>
    <div>
      <DashboardHeader />
      <DashboardHome />
      <DashboardFooter />
    </div>
</template>

<script>
  import DashboardHeader from '../components/DashboardHeader'
  import DashboardHome from '../components/DashboardHome'
  import DashboardFooter from '../components/DashboardFooter'

  export default {
    name: 'DashboardView',

    components: {
      DashboardHeader,
      DashboardHome,
      DashboardFooter,
    },
  }
</script>