<template>
    <v-container class="mt-16">
        <back-btn></back-btn>
        <h2 class="text-center">Blueprint Business Solution BPO Corporation</h2>
        <div class="subheading text-center">4th Floor Norkis Cyberpark Bakilid, Mandaue City, Cebu</div>
        <v-form class="mt-5">
            <v-row>
                <v-col
                    cols="6"
                >
                    <v-text-field
                        v-model="fullname"
                        label="Name:"
                        readonly
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="id_number"
                        label="ID Number:"
                        readonly
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="tin_no"
                        label="TIN:"
                        readonly
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="sss_no"
                        label="SSS Number:"
                        readonly
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="phic_no"
                        label="PHIC Number:"
                        readonly
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="hdmf_no"
                        label="HDMF Number:"
                        readonly
                    >
                    </v-text-field>
                </v-col>
                <v-col
                    cols="6"
                >
                    <v-text-field
                        v-model="pay_date"
                        label="Pay Date:"
                        readonly
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="date_covered"
                        label="Date Covered:"
                        readonly
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="tax_status"
                        label="Tax Status:"
                        readonly
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="department"
                        label="Department:"
                        readonly
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="position"
                        label="Position:"
                        readonly
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="daily_rate"
                        label="Daily Rate:"
                        readonly
                    >
                    </v-text-field>
                </v-col>
            </v-row>   
            <v-row>
                <v-col
                    cols="6"
                >
                    <h2>COMPENSATION</h2>
                    <v-text-field
                        v-model="basic_pay"
                        label="Basic Pay:"
                        readonly
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="totalAllowance"
                        label="Allowances:"
                        readonly
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="ot"
                        label="OT:"
                        readonly
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="ord_ot"
                        label="ORD-OT:"
                        readonly
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="ord_nd"
                        label="ORD-ND:"
                        readonly
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="paid_leaves"
                        label="Paid Leaves:"
                        readonly
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="holiday_pay"
                        label="Holiday Pay:"
                        readonly
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="special_holiday_pay"
                        label="Special Holiday Pay:"
                        readonly
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="other_earnings"
                        label="Other Earnings:"
                        readonly
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="thirteenth_month"
                        label="13th Month Pay:"
                        readonly
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="sales_commission"
                        label="Sales Commission:"
                        readonly
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="total_compensation"
                        label="Total Compensation"
                        class="font-weight-black"
                        color="#000000"
                        disabled
                    >
                    </v-text-field>
                </v-col>
                <v-col
                    cols="6"
                >
                    <h2>DEDUCTIONS</h2>
                    <v-text-field
                        v-model="absences"
                        label="Absences:"
                        readonly
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="tardiness"
                        label="Tardiness:"
                        readonly
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="undertime"
                        label="Undertime:"
                        readonly
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="sss_hdmf_loan"
                        label="SSS/HDMF Loan:"
                        readonly
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="others"
                        label="Others:"
                        readonly
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="sss"
                        label="SSS:"
                        readonly
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="phic"
                        label="PHIC:"
                        readonly
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="hdmf_pagibig"
                        label="HDMF/Pag-Ibig:"
                        readonly
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="phic_retroactive"
                        label="PHIC Retroactive:"
                        readonly
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="withholding_tax"
                        label="Withholding Tax:"
                        readonly
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="hmo"
                        label="HMO:"
                        readonly
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="total_deductions"
                        label="Total Deductions:"
                        class="font-weight-black"
                        color="#000000"
                        disabled
                    >
                    </v-text-field>
                </v-col>
            </v-row>
            <v-spacer class="ma-5">
            </v-spacer>
            <v-row>
                <v-col
                    cols="6"
                >
                    <v-text-field
                        v-model="take_home_pay"
                        label="Take Home Pay:"
                        class="font-weight-black"
                        color="#000000"
                        disabled
                    >
                    </v-text-field>
                </v-col>
            </v-row>
            <v-spacer class="ma-5">
            </v-spacer>
            <v-row>
                <v-col
                    cols="6"
                >
                    <v-text-field
                        v-model="prepared_by"
                        label="Prepared by:"
                        readonly
                    >
                    </v-text-field>
                </v-col>
                <v-col
                    cols="6"
                >
                    <v-text-field
                        v-model="received_by"
                        label="Received by:"
                        readonly
                    >
                    </v-text-field>
                </v-col>
            </v-row>
        </v-form>
    </v-container>
</template>

<script>
  import BackBtn from '../components/BackBtn'
  import axios from 'axios';

  export default {
    name: 'PayrollForm',

    components: {
        BackBtn
    },

    data() {
        return {
            fullname: '',
            id_number: '',
            tin_no: '0',
            sss_no: '0',
            phic_no: '0',
            hdmf_no: '0',
            pay_date: '0',
            date_covered: '0',
            tax_status: '',
            department: '',
            position: '',
            daily_rate: 0,
            basic_pay: 0,
            rice_allowance: 0,
            laundry_allowance: 0,
            uniform_allowance: 0,
            transportation_allowance: 0,
            ot: 0,
            ord_ot: 0,
            ord_nd: 0,
            paid_leaves: 0,
            holiday_pay: 0,
            special_holiday_pay: 0,
            other_earnings: 0,
            thirteenth_month: 0,
            sales_commission: 0,
            absences: 0,
            tardiness: 0,
            undertime: 0,
            sss_hdmf_loan: 0,
            others: 0,
            sss: 0,
            phic: 0,
            hdmf_pagibig: 0,
            phic_retroactive: 0,
            withholding_tax: 0,
            hmo: 0,
            prepared_by: 'admin',
            received_by: 'Employee Name',
        }
    },

    computed: {
        totalAllowance: {
            get: function () {
                return this.getTotalAllowance();
            },
            set: function () {}
        },

        total_compensation: {
            get: function () {
                if(this.basic_pay == '0') {
                    return this.thirteenth_month;
                }else{
                    return this.getTotalCompensation();
                }
            },
            set: function () {
            }
            
        },

        gross_salary: {
            get: function () {
                return this.getGrossSalary();
            },
            set: function () {}
        },

        loan_tax_other_deduction () {
            let loan_tax_other_deduction = parseFloat(this.hmo) + parseFloat(this.sss_loan) + parseFloat(this.hdmf_loan) 
                    + parseFloat(this.phic_retroactive) + parseFloat(this.withholding_tax) + parseFloat(this.others);

            return loan_tax_other_deduction;
        },

        total_deductions: {
            get: function () {
                let totalDeduction = parseFloat(this.total_compensation_deduction) + parseFloat(this.loan_tax_other_deduction);
                return totalDeduction.toFixed(2);
            },
            set: function () {
                let totalDeduction = '0.00';
                return totalDeduction;
            }
        },

        take_home_pay: {
            get: function () {
                let takeHomePay = parseFloat(this.gross_salary) - parseFloat(this.loan_tax_other_deduction);
                return takeHomePay.toFixed(2);
            },
            set: function () {
                let takeHomePay = '0.00';
                return takeHomePay;
            }
        },
    },

    methods: {
        getTotalCompensation () {
            let totalCompensation = parseFloat(this.basic_pay) + parseFloat(this.ot) + parseFloat(this.ord_ot) 
            + parseFloat(this.ord_nd) + parseFloat(this.paid_leaves) + parseFloat(this.holiday_pay) + parseFloat(this.special_holiday_pay)
            + parseFloat(this.salary_dispute) + + parseFloat(this.sales_commission) - this.total_compensation_deduction;
            
            return totalCompensation.toFixed(2);
        },

        getTotalAllowance () {
            let totalAllowance = parseFloat(this.rice_allowance) + parseFloat(this.laundry_allowance) + parseFloat(this.uniform_allowance)
                                 + parseFloat(this.transportation_allowance);

            return totalAllowance;
        },

        getGrossSalary () {
            let grossSalary = parseFloat(this.total_compensation) + parseFloat(this.totalAllowance) + parseFloat(this.other_earnings);
            return grossSalary.toFixed(2);
        },
    },

    mounted() {
      const data = JSON.parse(localStorage.getItem("tokenData"));
      const payrollID = JSON.parse(localStorage.getItem("payrollID"));
      const userID = JSON.parse(localStorage.getItem("userID"));

      axios
      .get(process.env.VUE_APP_ROOT_API + '/api/payroll/payslip/' + payrollID + '/' + userID, {
        headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${data.access_token}`
                }
      })
      .then(response => {
            this.fullname = response.data.fullname
            this.id_number = response.data.id_number
            this.tin_no = response.data.tin_no
            this.sss_no = response.data.sss_no
            this.phic_no = response.data.phic_no
            this.hdmf_no = response.data.hdmf_no
            this.pay_date = response.data.pay_date
            this.date_covered = response.data.date_covered
            this.tax_status = response.data.tax_status
            this.department = response.data.department
            this.position = response.data.position
            this.daily_rate = response.data.daily_rate
            this.basic_pay = response.data.basic_pay
            this.rice_allowance = response.data.rice_allowance
            this.laundry_allowance = response.data.laundry_allowance
            this.uniform_allowance = response.data.uniform_allowance
            this.transportation_allowance = response.data.transportation_allowance
            this.salary_dispute = response.data.salary_dispute
            this.sales_commission = response.data.sales_commission
            this.ot = response.data.ot
            this.ord_ot = response.data.ord_ot
            this.ord_nd = response.data.ord_nd
            this.paid_leaves = response.data.paid_leaves
            this.holiday_pay = response.data.holiday_pay
            this.special_holiday_pay = response.data.special_holiday_pay
            this.other_earnings = response.data.other_earnings
            this.thirteenth_month = response.data.total_thirteenth_month_pay
            this.total_compensation = response.data.total_compensation
            this.total_compensation_deduction = response.data.total_compensation_deduction
            this.absences = response.data.absences
            this.tardiness = response.data.tardiness
            this.undertime = response.data.undertime
            this.sss_loan = response.data.sss_loan
            this.hdmf_loan = response.data.hdmf_loan
            this.others = response.data.others
            this.sss = response.data.sss
            this.phic = response.data.phic
            this.hdmf_pagibig = response.data.hdmf_pagibig
            this.phic_retroactive = response.data.phic_retroactive
            this.withholding_tax = response.data.withholding_tax
            this.hmo = response.data.hmo
            this.total_deductions = response.data.total_deduction
            this.take_home_pay = response.data.take_home_pay
            this.prepared_by = response.data.prepared_by
            this.received_by = response.data.received_by
            this.sss_hdmf_loan = (parseFloat(response.data.sss_loan) + parseFloat(response.data.hdmf_loan)).toFixed(2)
      })
      .catch(error => {
          console.log(error)
      })
    },   
  }
</script>