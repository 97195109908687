<template>
    <div>
      <DashboardHeader />
      <PayrollGeneratedForm />
      <DashboardFooter />
    </div>
</template>

<script>
  import DashboardHeader from '../components/DashboardHeader'
  import PayrollGeneratedForm from '../components/PayrollGeneratedForm'
  import DashboardFooter from '../components/DashboardFooter'

  export default {
    name: 'PayrollGeneratedView',

    components: {
      DashboardHeader,
      PayrollGeneratedForm,
      DashboardFooter,
    },
  }
</script>